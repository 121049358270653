import React, {useEffect, useState} from 'react';
import './header.scss';
import {ReactComponent as MtnLogo} from '../../../assets/img/mtn-logo.svg';
import MenuIcon from '../../../assets/icons/menu.png';
import ShoppingCartIcon from '../../../assets/icons/shopping-cart.png';
import UserIcon from '../../../assets/icons/user.png';
import {BsSearch} from 'react-icons/bs';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {ToastContainer} from 'react-toastify';
import {useQuery} from 'react-query';
import {GetCustomerCart} from '../../../api/cart.service';
import {setLogout} from '../../../redux/user/userSlice';
import {getItem, storeItem} from '../../../api/storage.service';

const Header = () => {
	const pathname = useLocation();
	const navigate = useNavigate();
	const [handleNav, setHandleNav] = useState(false);
	const [cartItems, setCartItems] = useState();
	const user = useSelector((state) => state.user.user);
	const msisdn = getItem('u_msisdn');
	// const cartItems = useQuery({
	// 	queryKey: ['cartItems'],
	// 	queryFn: () => (msisdn ? GetCustomerCart(msisdn).then((res) => res.data.data) : null),
	// 	retry: 3,
	// });

	const dispatch = useDispatch();
	function openNav() {
		setHandleNav(true);
	}

	function closeNav() {
		setHandleNav(false);
	}

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	const handleLogout = () => {
		if (window.confirm('Are you sure you want to logout ?')) {
			dispatch(setLogout());
			window.location.href = '/';
			closeNav();
			window.localStorage.clear();
		}
	};

	const getCustomerCart = () => {
		GetCustomerCart(msisdn)
			.then((res) => {
				if (res.data.success) {
					setCartItems(res.data.data);
				} else {
				}
			})
			.catch((err) => {
				console.log('Error Log:', err);
			});
	};

	useEffect(() => {
		if (user) {
			getCustomerCart();
		}
	}, [user]);

	return (
		<>
			<div className='header__container'>
				<div className='header__navigation'>
					<Link to='/' className='mt-[10px]'>
						<MtnLogo />
					</Link>
					<div className='header__navItems'>
						{/* {menuItems.map((item, idx) => (
							<div onClick={() => handleNavigation(item)}>{item.text === 'Cart' && !user ? <></> : item.text === 'Login' && user ? <></> : <NavItem Icon={item.icon} Text={item.text} key={idx} />}</div> //if user exists hide login, and if there is no user hide cart
						))} */}
						{!user ? (
							<div
								className='navItem'
								onClick={() => {
									storeItem('AfterVerificationPath', window.location.pathname);
									navigate('/login');
								}}
							>
								<div className='icon__indicator placeholder'></div>
								<img src={UserIcon} className='navItem__Icon' />
								<p className='navItem__text'>Login</p>
							</div>
						) : (
							<></>
						)}
						{user ? (
							<div className='navItem' onClick={() => navigate('/cart')}>
								<div className='icon__indicator'>{cartItems?.productDetails?.length ?? 0}</div>
								<img src={ShoppingCartIcon} className='navItem__Icon' />
								<p className='navItem__text'>Cart</p>
							</div>
						) : (
							<></>
						)}
						<div className='navItem' onClick={openNav}>
							<div className='icon__indicator placeholder'></div>
							<img src={MenuIcon} className='navItem__Icon' />
							<p className='navItem__text'>Menu</p>
						</div>
					</div>
				</div>

				<div className='header__search centerItems'>
					<div className='search_box'>
						<input className='search_input' type='text' placeholder='Search Item' />
						<BsSearch className='search_icon' />
					</div>
				</div>
			</div>
			{/* remove [md:w-1/2 left-0] when desktop designs are provided*/}
			<div id='mySidenav' className={`h-full fixed top-0 right-0 bg-[#F2F2F2] overflow-x-hidden z-[100] ${handleNav ? 'w-full md:w-1/2 md:left-[50%] md:translate-x-[-50%]' : 'w-0'}`}>
				<div className='base-color items-center flex justify-between'>
					<div></div>
					<div onClick={closeNav} className='cursor-pointer'>
						<svg width='127' height='39' viewBox='0 0 127 39' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<path d='M127 0H0V19.5C0 24.6717 2.10714 29.6316 5.85786 33.2886C9.60859 36.9455 14.6957 39 20 39H127V0Z' fill='#FFCC00' />
							<path
								d='M43 21.802C41.305 21.802 40.24 20.572 40.24 18.712C40.24 16.867 41.305 15.637 43.105 15.637C44.125 15.637 45.22 16.027 46.06 16.657V14.302C45.145 13.837 44.035 13.537 42.88 13.537C39.82 13.537 37.63 15.757 37.63 18.967C37.63 21.982 39.565 24.007 42.55 24.007C44.44 24.007 45.895 23.197 46.885 22.222L45.79 20.707C45.1 21.292 44.095 21.802 43 21.802ZM48.2942 13.837V23.707H55.7192V21.697H50.7542V13.837H48.2942ZM61.5104 24.007C64.7204 24.007 66.8354 21.817 66.8354 18.622C66.8354 15.607 64.7954 13.537 61.7054 13.537C58.4954 13.537 56.3654 15.727 56.3654 18.922C56.3654 21.937 58.4204 24.007 61.5104 24.007ZM61.7054 21.997C60.0104 21.997 58.9454 20.647 58.9454 18.667C58.9454 16.807 59.9354 15.547 61.5104 15.547C63.2054 15.547 64.2554 16.897 64.2554 18.877C64.2554 20.737 63.2804 21.997 61.7054 21.997ZM72.6288 24.007C75.1188 24.007 76.7988 22.702 76.7988 20.722C76.7988 18.577 74.8038 18.067 73.1988 17.722C71.5938 17.377 70.7238 17.317 70.7238 16.612C70.7238 15.937 71.5038 15.547 72.7188 15.547C73.9188 15.547 75.1488 15.922 76.2288 16.627V14.377C75.2838 13.867 73.7688 13.537 72.5688 13.537C70.0788 13.537 68.2788 14.932 68.2788 16.957C68.2788 18.712 69.6138 19.597 72.0738 20.032C73.7088 20.317 74.3538 20.437 74.3538 21.067C74.3538 21.667 73.7538 21.997 72.6738 21.997C71.3988 21.997 69.9438 21.547 68.6538 20.902L67.9338 22.627C69.1038 23.407 70.9338 24.007 72.6288 24.007ZM81.0764 21.757V19.717H85.6364V17.767H81.0764V15.787H86.4614V13.837H78.6164V23.707H86.6414V21.757H81.0764Z'
								fill='black'
							/>
							<path d='M101.613 12.3576L96 17.2451L97.2565 18.3392L102.87 13.4517L101.613 12.3576Z' fill='black' />
							<path d='M102.87 21.2342L97.2568 16.3467L96.0003 17.4408L101.613 22.3283L102.87 21.2342Z' fill='black' />
						</svg>
					</div>
				</div>
				<div>
					<div className='pt-14 cursor-pointer'>
						{!user ? (
							<p
								className='py-5 border-white border-b-[2px] pl-24'
								onClick={() => {
									storeItem('AfterVerificationPath', window.location.pathname);
									navigate('/login');
									closeNav();
								}}
							>
								Login
							</p>
						) : (
							<>
								<p
									className='py-5 border-white border-b-[2px] pl-24'
									onClick={() => {
										navigate('/orders');
										closeNav();
									}}
								>
									Order History
								</p>
								<p className='py-5 border-white border-b-[2px] pl-24' onClick={handleLogout}>
									Logout
								</p>
							</>
						)}
					</div>
				</div>
			</div>
			<ToastContainer className='text-[12px]' position='top-center' autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme='light' />
		</>
	);
};

export default Header;
