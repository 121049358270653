const order = [{}, {}];

const orders = [
  {
    orderId: 2,
    oNumber: "#25332",
    oDate: "23 Nov 2022",
    oStatus: "Order Verified",
    dName: "Huawei E2323",
    dType: "Wireless Portable Router",
    oAmount: "223.23",
    nOfOrders: "4",
  },
  {
    orderId: 3,
    oNumber: "#433232",
    oDate: "24 Nov 2022",
    oStatus: "Order Paid",
    dName: "Huawei E2323",
    dType: "Wireless Portable Router",
    oAmount: "223.23",
    nOfOrders: "6",
  },
  {
    orderId: 1,
    oNumber: "#763232",
    oDate: "25 Nov 2022",
    oStatus: "Order Verified",
    dName: "Huawei E2323",
    dType: "Wireless Portable Router",
    oAmount: "223.23",
    nOfOrders: "5",
  },
];

export { orders, order };
