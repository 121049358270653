function NumberFieldEntry({data, index}) {
	return (
		<>
			<div className='flexColumn requestEntry_field_container'>
				<div className={'error-placeholder error-comp-' + data?.componentId}></div>
				<input required={data?.required} type='text' className='requestEntry_formFields' placeholder={data?.options.filter((x) => x.key === 'PLACEHOLDER')[0]?.value} name={`response[${index}][value]`} />
				{/* <small className={(data?.required ? "required requestEntry_label" : 'requestEntry_label')}>{data?.displayName}</small> */}

				<input type='hidden' name={`response[${index}][meta]`} />
				<input type='hidden' name={`response[${index}][componentId]`} value={data?.componentId} />
				<input type='hidden' name={`response[${index}][id]`} value={data?.id} />
			</div>
		</>
	);
}

export default NumberFieldEntry;
