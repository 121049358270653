import {createSlice} from '@reduxjs/toolkit';

const initialState = {
	cartItems: [],
	cartData: null,
};

export const cartSlice = createSlice({
	name: 'product',
	initialState,
	reducers: {
		addCartItem: (state, action) => {
			state.cartItems = [...state.cartItems, action.payload];
		},
		removeCartItem: (state, action) => {
			state.cartItems = state.cart.filter((obj) => obj.productPackageId !== action.payload);
		},
		setCartItems: (state, action) => {
			state.cartItems = action.payload;
		},
    setCartData: (state, action) => {
			state.cartData = action.payload;
		},
	},
});

export const {addCartItem, removeCartItem, setCartItems, setCartData} = cartSlice.actions;

export default cartSlice.reducer;
