import React, { useState } from 'react';
import { order } from './ordersData';
import CheckIcon from '../../assets/icons/check.svg';
import ReturnButton from '../../components/reusable/returnButton/ReturnButton';
import { useParams } from 'react-router-dom';
import { GetOrderById } from '../../api/orders.service';
import { useAlertMessage } from '../../hooks/useAlertMessage';
import { useDispatch } from 'react-redux';
import { setAppLoading } from '../../redux/app/appSlice';
import { useEffect } from 'react';
import moment from 'moment';
import SMEImg from '../../assets/img/SMEProductImg.jpg';

function OrderStatusRadio({checked}) {
	return <div className={`order__status__radio ${checked ? 'checked' : 'unchecked'}`}>{checked && <img src={CheckIcon} />}</div>;
}

const OrderDetails = () => {
	const {order_id} = useParams();
	const dispatch = useDispatch();
	const [orderDetails, setOrderDetails] = useState();
	let {showSuccessMessage, showErrorMessage} = useAlertMessage();
	let [orderStatuses, _] = useState(['Order Paid', 'Order Verfied', 'Account created', 'Installation scheduled', 'Pending Sign']);

	const getOrderDetails = () => {
		dispatch(setAppLoading(true));
		GetOrderById(order_id)
			.then((res) => {
				dispatch(setAppLoading(false));
				if (res.data.success) {
					setOrderDetails(res?.data?.data);
				} else {
					showErrorMessage(res.data.message, 'error');
				}
			})
			.catch((err) => {
				dispatch(setAppLoading(false));
				showErrorMessage('Sorry! An error occured ', 'error');
				console.log('Error Log:', err);
			});
	};

	useEffect(() => {
		getOrderDetails();
	}, []);

	return (
		<div className='order__card__main'>
			<ReturnButton />
			<h3 className='text-xl font-bold mt-5'>Your Order</h3>
			<p className='text-xl font-bold'>Orders Pending</p>
			<h4 className='font-bold'>{`Order #${orderDetails?.orderNumber}`}</h4>
			<h4 className='huawei-text text-sm'>{`Ordered: ${moment(orderDetails?.orderDate).format('Do MMMM YYYY')}`}</h4>
			<button className='text-xs margin-bottom-30 mt-2 text-center py-[3px] px-4 bg-[#ffca05] border-none h-auto rounded-[15px]'>{orderDetails?.orderStatus.toLowerCase() === 'not paid' ? 'Not Paid' : 'Paid: xxxx'}</button>

			{orderDetails?.orderItems?.map((item, index) => (
				<div key={index} className='order__item__container'>
					<div className='yellow__image__container'>
						<img src={SMEImg} className='item__image rounded-xl' />
					</div>
					<div className='item__description__column pl-3'>
						<h5 className='text-xs font-bold'>{item?.productName}</h5>
						<h4 className='text-xs font-bold mb-2'>{item?.productPackageName}</h4>
						<small className='text-gray-500'>{item?.productPackageShortDescription}</small>
						{/* <div className='description__row'>
							<div className='description__bullet'></div>
							<h6 className='huawei-text text-xs'>Includes Broadband Router</h6>
						</div>

						<div className='description__row'>
							<div className='description__bullet'></div>
							<h6 className='huawei-text text-xs'>Up to 64 connected devices</h6>
						</div>

						<div className='description__row'>
							<div className='description__bullet'></div>
							<h6 className='huawei-text text-xs'>Free installation</h6>
						</div> */}
					</div>
				</div>
			))}
			
			{/* <div className='pt-16'>
				<div className='order__status__container'>
					<div className='row__start'>
						<h1 className='order__status_title'>Status: Order Paid</h1>
					</div>

					<div className='order__status__container_horizontal__line'></div>

					<div className='row__start order__status__content__container'>
						<div className='status__check__fields'>
							{orderStatuses.map((orderStatus, i) => (
								<OrderStatusRadio key={i} checked={i == 0} />
							))}
						</div>
						<div className='order__status__names'>
							{orderStatuses.map((orderStatus, i) => (
								<div className={`order__status__name ${i != 0 && 'text-grey'}`} key={i}>
									{orderStatus}
								</div>
							))}
						</div>
					</div>

					<div className='order__status__container_horizontal__line'></div>

					<span className='order__status__fullfillment__details__text'>Fulfillment details</span>
				</div>
			</div>

			<div className='order__status__container_horizontal__line margin-top-20'></div>

			<div className='fulfillment__details__section'>
				<div className=''>
					<h3 className='fulfillment__details__section_title'>Fulfillment details</h3>
					<div className='row__start col'>
						{orderStatuses.map((e, i) => (
							<>
								<h2
									style={{
										fontWeight: '500',
										marginBottom: '10px',
										marginTop: '10px',
									}}
								>
									{e}
								</h2>
								<h2 className='fulfillment__date'>15 May 2022, 2:00pm</h2>
							</>
						))}
					</div>
				</div>
			</div> */}
		</div>
	);
};

export default OrderDetails;
