import {useState} from 'react';

function CheckboxEntry({data, index}) {
	const [selected, setSelected] = useState([]);
	const separator = ';;';

	const items = () => data?.options?.filter((x) => x.key == 'ITEMS')[0].value.split(separator);
	const isVertical = () => data?.options?.find((x) => x.key == 'DIRECTION').value == 'vertical';

	const checkStyles = {
		fontSize: '12px',
		color: 'red !important',
		width: '289px',
		textAlign: 'left',
	};

	const checkField_containerStyles = {
		marginRight: '183px',
	};

	const onItemSelectionHandler = (e) => {
		const {value, checked} = e.target;

		if (checked) {
			const updatedCheckedState = selected.filter((item) => item !== value);
			setSelected([...updatedCheckedState, value]);
		} else {
			const updatedCheckedState = selected.filter((item) => item !== value);
			setSelected([...updatedCheckedState]);
		}
	};

	function deRequireCb(e) {
		const el = document.getElementsByClassName('.cb_' + data?.componentId);

		var atLeastOneChecked = false; //at least one cb is checked
		for (let i = 0; i < el.length; i++) {
			if (el[i].checked === true) {
				atLeastOneChecked = true;
			}
		}

		if (atLeastOneChecked === true) {
			for (let i = 0; i < el.length; i++) {
				el[i].required = false;
			}
		} else {
			for (let i = 0; i < el.length; i++) {
				el[i].required = true;
			}
		}
	}

	return (
		<>
			<div className='flexColumn requestEntry_field_container' style={checkStyles}>
				<div className={'error-placeholder error-comp-' + data?.componentId}></div>
				{isVertical() ? (
					<div className='radioField_container' style={checkField_containerStyles}>
						{items().map((item, item_key) => (
							<div key={item_key}>
								<input id={'cb_' + data?.componentId + '_' + item_key} onClick={deRequireCb} onChange={onItemSelectionHandler} className={'cb_' + data?.componentId} name={'cb_' + data?.componentId + '_' + item_key} type='checkbox' value={item} />
								<label style={{marginLeft: '10px'}} htmlFor={'cb_' + data?.componentId + '_' + item_key}>
									{item}
								</label>
							</div>
						))}
						<small className={data?.required ? 'required requestEntry_label' : 'requestEntry_label'}>{data?.displayName}</small>
					</div>
				) : (
					<>
						{items().map((item, item_key) => (
							<label key={item_key} style={{marginRight: '10px'}} htmlFor={'cb_' + data?.componentId + '_' + item_key}>
								<input id={'cb_' + data?.componentId + '_' + item_key} onClick={deRequireCb} onChange={onItemSelectionHandler} className={`cb_${data?.componentId} mr-2`} name={'cb_' + data?.componentId + '_' + item_key} type='checkbox' value={item} />
								{item}
							</label>
						))}
						<br></br>
						<small className={data?.required ? 'required requestEntry_label' : 'requestEntry_label'}>{data?.displayName}</small>
					</>
				)}

				<input type='hidden' name={`response[${index}][meta]`} />
				<input type='hidden' name={`response[${index}][value]`} value={selected.join(separator)} />
				<input type='hidden' name={`response[${index}][componentId]`} value={data?.componentId} />
				<input type='hidden' name={`response[${index}][id]`} value={data?.id} />
			</div>
		</>
	);
}

export default CheckboxEntry;
