import React from 'react'
import { useNavigate } from 'react-router-dom'
import './ReturnButton.scss'

const ReturnButton = () => {
    const navigate = useNavigate();

    const handleNavigation = () => {
        navigate(-1);
    }

  return (
    <button onClick={handleNavigation} className='returnButton'>BACK</button>
  )
}

export default ReturnButton