import React, {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useQuery} from 'react-query';
import {GetProductByPermalink} from '../../api/products.service';
import Variable from '../../components/reusable/productTypes/Variable';
import Simple from '../../components/reusable/productTypes/Simple';
import Group from '../../components/reusable/productTypes/Group';
import {Modal} from '../../components/reusable/modal/_component';
import ShareButtons from '../../components/reusable/shareButtons/ShareButtons';
import ReturnButton from '../../components/reusable/returnButton/ReturnButton';
import {truncateText} from '../../utils';
import {useDispatch, useSelector} from 'react-redux';
import {setAppLoading} from '../../redux/app/appSlice';
import {useEffect} from 'react';
import ExternalVar from '../../components/reusable/productTypes/ExternalVar';
import PageTitle from '../../components/reusable/pageTitle/PageTitle';
import {setLogout} from '../../redux/user/userSlice';
import {storeItem} from '../../api/storage.service';

const Product = () => {
	const {productPermalink} = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [showModal, setShowModal] = useState(false);
	const imagePath = useSelector((state) => state.app.config.apiBaseUrl.assetsBaseUrl);
	const userState = useSelector((state) => state.user);
	const productQuery = useQuery({
		retry: (count, err) => count < 3,
		staleTime: Infinity,
		queryKey: ['product', productPermalink],
		queryFn: () => GetProductByPermalink({permalink: productPermalink}).then((res) => res.data),
		// onSuccess: (data) => onProductFetchSuccess(data),
	});

	const authenticateUser = () => {
		setShowModal(true);
	};
	useEffect(() => {
		dispatch(setAppLoading(productQuery?.isFetching));
	}, [productQuery?.isFetching]);

	const proceedToLogin = () => {
		if (userState?.user && !userState?.userInformation?.userExists) {
			dispatch(setLogout());
			navigate('/login');
		} else {
			navigate('/login');
		}
	};

	const isPreOrder = () => {
		if ((productQuery?.data?.data?.productVariation?.toLowerCase() === 'variation' || productQuery?.data?.data?.productVariation?.toLowerCase() === 'simple') && productQuery?.data?.data?.productType?.toLowerCase() === 'physical') {
			return productQuery?.data?.data?.preOrder;
		}

		return false;
	};

	return (
		// <>
		// 	{productQuery?.data?.data?.productVariation?.toLowerCase() === 'variation' && <Variable selectedProduct={productQuery?.data?.data} />}
		// 	{productQuery?.data?.data?.productVariation?.toLowerCase() === 'simple' && <Simple selectedProduct={productQuery?.data?.data} />}
		// 	{productQuery?.data?.data?.productVariation?.toLowerCase() === 'group' && <Group selectedProduct={productQuery?.data?.data} />}
		// 	{productQuery?.isFetching && <div className='h-[500px] grid place-items-center'>...Loading</div>}
		// </>
		<>
			<PageTitle title={`Business Hub - ${productQuery?.data?.data?.productName}`} />

			<div className='SME__container'>
				<ReturnButton />
				<h1 className='text-[30px] font-bold mt-2'>{truncateText(productQuery?.data?.data?.productName, 20)}</h1>
				<ShareButtons />
				<div
					className='card'
					style={{
						backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), 
						url(${imagePath + '/' + productQuery?.data?.data?.productImages[0]})`,
					}}
				>
					<div className='cardTag'>{truncateText(productQuery?.data?.data?.productName, 20)}</div>
					<div className='cardInner'>
						{productQuery?.data?.data?.productVariation?.toLowerCase() === 'variation' && <Variable onAuthenticateUser={authenticateUser} selectedProduct={productQuery?.data?.data} isPreOrder={isPreOrder} />}
						{productQuery?.data?.data?.productVariation?.toLowerCase() === 'simple' && <Simple onAuthenticateUser={authenticateUser} selectedProduct={productQuery?.data?.data} isPreOrder={isPreOrder} />}
						{productQuery?.data?.data?.productVariation?.toLowerCase() === 'group' && <Group onAuthenticateUser={authenticateUser} selectedProduct={productQuery?.data?.data} isPreOrder={isPreOrder} />}
						{productQuery?.data?.data?.productVariation?.toLowerCase() === 'external' && <ExternalVar onAuthenticateUser={authenticateUser} selectedProduct={productQuery?.data?.data} isPreOrder={isPreOrder} />}
						{productQuery?.isFetching && <div className='h-[500px] grid place-items-center'>...Loading</div>}
						<div className='cardButtons_description'>
							<p>Description</p>
							<p className='desc_text'>{productQuery?.data?.data?.productDescription}</p>
						</div>
					</div>
				</div>
			</div>
			<Modal open={showModal} close={setShowModal}>
				<div className='block px-8 py-12 rounded-2xl bg-white w-[350px]'>
					<div className='grid place-items-center'>
						<svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<path d='M24 46.5C36.4264 46.5 46.5 36.4264 46.5 24C46.5 11.5736 36.4264 1.5 24 1.5C11.5736 1.5 1.5 11.5736 1.5 24C1.5 36.4264 11.5736 46.5 24 46.5Z' fill='#FFCA05' />
							<path d='M20.25 20.8506H27.75V38.8506H20.25V20.8506Z' fill='white' />
							<path d='M24 16.6504C26.0711 16.6504 27.75 14.9715 27.75 12.9004C27.75 10.8293 26.0711 9.15039 24 9.15039C21.9289 9.15039 20.25 10.8293 20.25 12.9004C20.25 14.9715 21.9289 16.6504 24 16.6504Z' fill='white' />
						</svg>
					</div>
					<p className='text-center font-semibold text-[18px] mt-4'>Check out with account</p>
					<p className='text-center font-light mt-2 text-[13px] text-gray-500'>Create an account or login to checkout with your profile.</p>
					<button className='rounded-full w-full bg-[#ffcc00] p-2 font-bold mt-9' onClick={() => proceedToLogin()}>
						Proceed
					</button>
				</div>
			</Modal>
			{/* DONT DELETE */}

			{/* <FrequentlyBought/>
  <Freebies/> */}
		</>
	);
};

export default Product;
