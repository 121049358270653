import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {CheckMomoPaymentStatus} from '../../api/payment.service';
import {getItem} from '../../api/storage.service';
import ReturnButton from '../../components/reusable/returnButton/ReturnButton';
import {Timer} from '../../components/reusable/timer/Timer';
import {useAlertMessage} from '../../hooks/useAlertMessage';
import {setPaymentIsSuccessful, setPaymentErrorMessage} from '../../redux/product/productSlice';
import Countdown from 'react-countdown';

const SessionExpired = () => {
	const navigate = useNavigate();

	const renderer = ({minutes, seconds, completed}) => {
		if (completed) {
			return navigate('/login');
		} else {
			return (
				<div className='text-sm text-center mt-12'>
					Redirecting to login screen in <span className='min font-bold'>{minutes}</span>:<span className='sec font-bold'>{seconds}</span> secs
				</div>
			);
		}
	};

	return (
		<div className='py-[40px] px-[20px] pb-0 bg-white min-h-[500px]'>
			<div>
				<div className='grid place-items-center'>
					<svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<path d='M24 46.5C36.4264 46.5 46.5 36.4264 46.5 24C46.5 11.5736 36.4264 1.5 24 1.5C11.5736 1.5 1.5 11.5736 1.5 24C1.5 36.4264 11.5736 46.5 24 46.5Z' fill='#FFCA05' />
						<path d='M20.25 20.8506H27.75V38.8506H20.25V20.8506Z' fill='white' />
						<path d='M24 16.6504C26.0711 16.6504 27.75 14.9715 27.75 12.9004C27.75 10.8293 26.0711 9.15039 24 9.15039C21.9289 9.15039 20.25 10.8293 20.25 12.9004C20.25 14.9715 21.9289 16.6504 24 16.6504Z' fill='white' />
					</svg>
				</div>
				<h1 className='text-center text-black font-bold text-[25px] mt-5'>Session Expired</h1>
				<p className='text-center text-[14px] mt-[10px]'>Kindly login again to continue</p>
				<Countdown date={Date.now() + 59000} renderer={renderer} />
			</div>

			<div className='text-sm rounded-full w-6/12 text-center cursor-pointer mx-auto bg-[#ffcc00] p-3 font-bold mt-28 hover:bg-[#ffcc00a8]' onClick={() => navigate('/login')}>
				Proceed to Login
			</div>
		</div>
	);
};

export default SessionExpired;
