import React, {useEffect, useState} from 'react';
import './app.scss';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {storeItem} from './api/storage.service';
import {setBaseApi} from './utils';
import {GetFrontendConfig} from './api/app.service';
import {Loader} from './components/reusable/Loader/_component';
import {useDispatch, useSelector} from 'react-redux';
import Header from './components/layout/header/Header';
import Footer from './components/layout/footer/Footer';
import Product from './views/Product/Product';
import Home from './views/Home/Home';
import Login from './views/Login/Login';
import VerifyNumber from './views/VerifyNumber/VerifyNumber';
import ChoosePayment from './views/ChoosePayment/ChoosePayment';
import SelectBeneficiary from './views/SelectBeneficiary/SelectBeneficiary';
import DynamicBundle from './views/DynamicBundle/DynamicBundle';
import OrderDetails from './views/orders/OrderDetails';
import Map from './views/map/Map';
import Cart from './views/Cart/Cart';
import 'react-toastify/dist/ReactToastify.css';
import Orders from './views/orders/Orders';
import ConfirmPayment from './views/confirmPayment/ConfirmPayment';
import PaymentStatus from './views/PaymentStatus/PaymentStatus';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Signup from './views/Signup/Signup';
import {SessionExpiryModal} from './components/reusable/modal/SessionExpiryModal';
import SessionExpired from './views/SessionExpired/SessionExpired';
import {setFrontendConfig} from './redux/app/appSlice';
// import ReactGA from 'react-ga';

function App() {
	const [configLoaded, setConfigLoaded] = useState(false);
	const appState = useSelector((state) => state.app);
	const user = useSelector((state) => state.user.user);
	const dispatch = useDispatch();

	useEffect(() => {
		document.body.style.overflow = appState.loading ? 'hidden' : 'auto';
	}, [appState?.loading]);

	useEffect(() => {
		if (!configLoaded) {
			GetFrontendConfig().then((res) => {
				storeItem('appData', JSON.stringify(res.data));
				// document.title = res.data.appName;
				dispatch(setFrontendConfig(res.data));

				setBaseApi(res.data.apiBaseUrl);
				setConfigLoaded(true);
				// ReactGA.initialize(res.data.googleAnalyticsCode);
			});
		}
		return () => {};
	}, []);

	// useEffect(() => {
	// 	ReactGA.pageview(window.location.pathname + window.location.search);
	// }, []);

	return (
		<Router>
			{appState.loading ? (
				<div className='fixed inset-0 flex justify-center bg-gray-50 bg-opacity-70 items-center h-full z-30'>
					<Loader />
				</div>
			) : null}
			{configLoaded ? (
				<>
					{/* remove md:w-1/2 md:mx-auto when desktop designs are provided*/}
					<div className='layout_sds_container overflow-x-hidden w-full md:w-1/2 md:mx-auto'>
						<Header />
						<Routes>
							{user ? (
								<>
									<Route path='*' element={<Home />} />
									<Route path='/:productPermalink' element={<Product />} />
									<Route path='/choose-payment' element={<ChoosePayment />} />
									<Route path='/select-beneficiary' element={<SelectBeneficiary />} />
									<Route path='/payment-status' element={<PaymentStatus />} />
									<Route path='/verify-number' element={<VerifyNumber />} />
									<Route path='/order-details/:order_id' element={<OrderDetails />} />
									<Route path='/map' element={<Map />} />
									<Route path='/cart' element={<Cart />} />
									<Route path='/orders' element={<Orders />} />
									<Route path='/confirm-payment' element={<ConfirmPayment />} />
									<Route path='/signup' element={<Signup />} />
									<Route path='/session-expired' element={<SessionExpired />} />
								</>
							) : (
								<>
									<Route path='*' element={<Home />} />
									<Route path='/:productPermalink' element={<Product />} />
									<Route path='/login' element={<Login />} />
									<Route path='/verify-number' element={<VerifyNumber />} />
									<Route path='/session-expired' element={<SessionExpired />} />
								</>
							)}
						</Routes>
						<Footer />
					</div>
				</>
			) : null}
		</Router>
	);
}

export default App;
