import moment from 'moment';

function DateFieldEntry({data, index}) {
	const dateFieldStyles = {
		fontSize: '12px',
		color: '#141414c0',
	};
	const isFutureDatePrevented = () => data?.options?.filter((x) => x.key == 'PREVENT-FUTURE-DATE')[0]?.value.toString() == 'true';
	const enableTimePicker = () => data?.options?.filter((x) => x.key == 'ENABLE-TIME-PICKER')[0]?.value.toString() == 'true';
	const minAge = () => data?.options?.filter((x) => x.key == 'MIN-AGE')[0]?.value;

	return (
		<>
			<div className='flexColumn requestEntry_field_container'>
				<div className={'error-placeholder error-comp-' + data?.componentId}></div>
				<input
					style={dateFieldStyles}
					name={`response[${index}][value]`}
					type={enableTimePicker() ? 'datetime-local' : 'date'}
					className='requestEntry_formFields'
					placeholder='placeholder'
					{...(Number(minAge()) == 0 && isFutureDatePrevented() && {max: moment().format('yyyy-MM-DD')})}
					{...(Number(minAge()) > 0 && {max: moment().subtract(Number(minAge()), 'years').format('yyyy-MM-DD')})}
				/>
				<small className={data?.required ? 'required requestEntry_label' : 'requestEntry_label'}>{data?.displayName}</small>

				<input type='hidden' name={`response[${index}][meta]`} />
				<input type='hidden' name={`response[${index}][componentId]`} value={data?.componentId} />
				<input type='hidden' name={`response[${index}][id]`} value={data?.id} />
			</div>
		</>
	);
}

export default DateFieldEntry;
