{
	/* <input type='text' name='beneficiary-no' id='beneficiary-no' placeholder='Beneficiary number' className='sbeneficiary_field' /> */
}

function TextFieldEntry({data, index}) {
	return (
		<>
			<div className='flexColumn requestEntry_field_container'>
				<div className={'error-placeholder error-comp-' + data?.componentId}></div>
				{data?.options.filter((x) => x.key === 'MULTI-LINE')[0]?.value === 'true' ? (
					<textarea
						className='requestEntry_formFields'
						placeholder={data?.options.filter((x) => x.key === 'PLACEHOLDER')[0]?.value}
						name={`response[${index}][value]`}
						minLength={data?.options.filter((x) => x.key === 'MIN-LEN')[3]?.value}
						maxLength={data?.options.filter((x) => x.key === 'MAX-LEN')[1]?.value}
						required={data?.required}
					></textarea>
				) : (
					<input
						type='text'
						className='requestEntry_formFields'
						placeholder={data?.options.filter((x) => x.key === 'PLACEHOLDER')[0]?.value}
						name={`response[${index}][value]`}
						minLength={data?.options.filter((x) => x.key === 'MIN-LEN')[3]?.value}
						maxLength={data?.options.filter((x) => x.key === 'MAX-LEN')[1]?.value}
						required={data?.required}
					/>
				)}

				<input type='hidden' name={`response[${index}][meta]`} />
				<input type='hidden' name={`response[${index}][componentId]`} value={data?.componentId} />
				<input type='hidden' name={`response[${index}][id]`} value={data?.id} />
			</div>
		</>
	);
}

export default TextFieldEntry;
