import {getAxios, getConfig} from '../utils';

const apiAxios = () => getAxios(getConfig().apiBaseUrl.utilitiesBaseUrl);
// const apiAxios = () => getAxios('https://localhost:44390/api');

export const UserLogin = async (payload) => await apiAxios().post('/Auth/login', {...payload});
export const VerifyOtp = async (payload) => await apiAxios().post('/Auth/verify-otp', {...payload});
export const ResendOtp = async (payload) => await apiAxios().post('/Auth/Resend-OTP', {...payload});

export const AddShopUser = async (payload) => await apiAxios().post('/Auth/AddShopUser', {...payload});
export const CheckShopUser = async (payload) => await apiAxios().post('/Auth/ShopUserExistsByMsisdnCheck', {...payload});

export const GetHomepageSliders = async () => await apiAxios().post('/Auth/GetMainImageSliders');
