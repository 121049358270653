import {getAxios, getConfig} from '../utils';
import {getItem} from './storage.service';

const apiAxios = () => getAxios(getConfig().apiBaseUrl.ordersBaseUrl);
// const apiAxios = () => getAxios('http://localhost:5000/api');

// export const AddItemToCart = async (payload) => {
// 	const response = await apiAxios().post('/Cart/AddItemToCart', {...payload});
// 	if (response.data.success) {
// 		return response.data.data;
// 	}
// 	return null;
// };

export const AddItemToCartNew = async (payload) => {
	const response = await apiAxios().post('/Cart/AddItemToCartNew', {...payload});
	if (response.data.success) {
		return response.data.data;
	}
	return null;
};

export const GetCustomerCart = async (userMsisdn) => await apiAxios().post('/Cart/GetCustomerCart', {userId: userMsisdn});

export const RemoveItemFromCart = async (id) => {
	const response = await apiAxios().post('/Cart/RemoveItemFromCart', {id});
	if (response.data.success) {
		return response.data.data;
	}
};

export const SelectItemInCart = async (id) => {
	const response = await apiAxios().post('/Cart/SelectItemInCart', {id});
	if (response.data.success) {
		return response.data.data;
	}
};

export const UnCheckItemInCart = async (id) => {
	const response = await apiAxios().post('/Cart/UnCheckItemInCart', {id});
	if (response.data.success) {
		return response.data.data;
	}
};

export const SelectAllItemsInCart = async (id) => {
	const response = await apiAxios().post('/Cart/SelectAllItemsInCart', {id});
	if (response.data.success) {
		return response.data.data;
	}
};

export const UnCheckAllItemsInCart = async (id) => {
	const response = await apiAxios().post('/Cart/UnCheckAllItemsInCart', {id});
	if (response.data.success) {
		return response.data.data;
	}
};

export const Checkout = async (payload) => await apiAxios().post('/Cart/Checkout', {...payload});
export const BuyNow = async (payload) => await apiAxios().post('/Cart/PayNow', {...payload});
export const AddItemToCart = async (payload) => await apiAxios().post('/Cart/AddItemToCartNew', {...payload});


