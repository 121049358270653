import React, {useEffect, useRef, useState} from 'react';
import ReturnButton from '../../components/reusable/returnButton/ReturnButton';
import './map.scss';
import LocationPinIcon from '../../assets/icons/locationPin.png';
import YellowPinIcon from '../../assets/icons/yellowpin.png';
import {useNavigate} from 'react-router-dom';
import {Loader} from 'google-maps';
import mapStyle from './mapStyle';
import Autocomplete from '../../components/reusable/autocomplete/Autocomplete';
import {BsSearch} from 'react-icons/bs';
import {selectLocation} from '../../redux/location/locationSlice';
import {getItem, storeItem} from '../../api/storage.service';
import {useAlertMessage} from '../../hooks/useAlertMessage';

const options = {
	language: 'en',
	region: 'ghana',
	libraries: ['places'],
};

const loader = new Loader(process.env.REACT_APP_GOOGLE_MAPS_API_KEY, options);

const Map = () => {
	const navigate = useNavigate();
	const [google, setGoogle] = useState();
	const storedLocationDetails = getItem('coordinates');
	const locationDetails = JSON.parse(storedLocationDetails);
	const [locationInfo, setLocationInfo] = useState(false);
	let {showSuccessMessage, showErrorMessage} = useAlertMessage();

	const defaultCenter = {
		lat: locationDetails?.latitude,
		lng: locationDetails?.longitude,
	};

	const containerStyle = {
		width: '100%',
		height: '320px',
		display: locationInfo ? 'block' : 'none',
	};

	const mapResultStyle = {
		height: !locationInfo ? '288px' : '',
	};

	loader.load().then((response) => setGoogle(response));

	useEffect(() => {
		if (google) {
			var map = new google.maps.Map(document.getElementById('map'), {
				center: defaultCenter,
				zoom: 15,
				disableDefaultUI: true,
				gestureHandling: 'greedy',
				styles: mapStyle,
			});
			new google.maps.Marker({
				position: defaultCenter,
				map,
				title: 'Hello World!',
				icon: YellowPinIcon,
			});
		}
		if (locationDetails?.latitude) {
			setLocationInfo(true);
		}
	}, [google, locationDetails?.latitude]);

	const getMyLocation = () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(showPosition);
		} else {
			showErrorMessage('Geolocation is not supported by this browser', 'error');
		}
	};

	const showPosition = (position) => {
		const lat = position.coords.latitude;
		const lng = position.coords.longitude;

		window.location.href = '/map?lat=' + lat + '&lng=' + lng;
		const coordinates = {
			latitude: lat,
			longitude: lng,
			placeName: 'Current Location',
		};
		storeItem(JSON.stringify(coordinates), 'coordinates');
	};

	return (
		<div>
			<div className='map__container'>
				<ReturnButton />
				<h1 className='map__header'>Set location</h1>
				<p>Type in to set your location</p>
			</div>

			<div style={containerStyle} id='map'></div>

			<div className='map__search_container'>
				<div className='map_search_box'>
					{locationInfo ? <img src={LocationPinIcon} alt='img' className='map_search_icon' /> : <BsSearch className='location_search_icon' />}

					{google ? <Autocomplete google={google} /> : ''}
				</div>
			</div>

			<div style={{background: 'white'}}>
				<div>
					<div id='results' style={mapResultStyle} className='map_results_container'>
						<div className='useMyLocation' onClick={() => getMyLocation()}>
							Use My Location
						</div>
					</div>
				</div>

				<div className='map_button_container'>
					<button className='map_button'>Confirm location</button>
				</div>
			</div>
		</div>
	);
};

export default Map;
