function DropdownListEntry({data, index}) {
	const separator = ';;';
	const items = () => data?.options?.filter((x) => x.key == 'ITEMS')[0].value.split(separator);

	return (
		<>
			<div className='flexColumn requestEntry_field_container'>
				<div className={'error-placeholder error-comp-' + data?.componentId}></div>
				<select name={`response[${index}][value]`} className='flexColumn requestEntry_formFields' id='select-inputField'>
					<option value=''>{data?.options.filter((x) => x.key === 'PLACEHOLDER')[0]?.value}</option>
					{items().map((item, i) => (
						<option value={item} key={i}>
							{item}
						</option>
					))}
				</select>
				{/* <small className={(data?.required ? "required requestEntry_label" : 'requestEntry_label')}>{data?.displayName}</small> */}

				<input type='hidden' name={`response[${index}][meta]`} />
				<input type='hidden' name={`response[${index}][componentId]`} value={data?.componentId} />
				<input type='hidden' name={`response[${index}][id]`} value={data?.id} />
			</div>
		</>
	);
}

export default DropdownListEntry;
