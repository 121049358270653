import React from "react";
import { useNavigate } from "react-router-dom";
import "./ShareButtons.scss";
import { ReactComponent as FacebookIcon } from "../../../assets/icons/FacebookIcon.svg";
import { ReactComponent as TwitterIcon } from "../../../assets/icons/TwitterIcon.svg";

const ShareButtons = () => {
  return (
    <>
      <div className="shareButtons customFlex">
        <p className="shareButtons_text">Share</p>
        <div className="flex items-center">
          <FacebookIcon className="shareButton_icon" />
          <TwitterIcon className="shareButton_icon"/>
        </div>
      </div>
    </>
  );
};

export default ShareButtons;
