import React, { useState } from 'react'
import './cartcardcounter.scss';

function CartCardCounter() {
    const [counter, setCounter] =  useState(1);
    const increaseCounter = () => setCounter(counter+1);
    const decreaseCounter = () => setCounter(counter === 0 ? 0 : counter-1);

  return (
    <>
        <div className="cartCardcounter__container customFlex justifyEvenly">
            <small className='cartCardcounter__decrease' onClick={decreaseCounter}>-</small>
            <small className='cartCardcounter__number'>{counter}</small>
            <small className='cartCardcounter__increase' onClick={increaseCounter}>+</small>
        </div>  
    </>
  )
}

export default CartCardCounter