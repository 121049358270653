import React, {useEffect, useState} from 'react';
import './paymentstatus.scss';
import {ReactComponent as MtnLogo} from '../../assets/img/mtn_logo_modal.svg';
import ReturnButton from '../../components/reusable/returnButton/ReturnButton';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import PageTitle from '../../components/reusable/pageTitle/PageTitle';
import {getItem} from '../../api/storage.service';
import {useQuery} from 'react-query';
import {GetOrderById} from '../../api/orders.service';

const PaymentStatus = () => {
	let productSlice = useSelector((state) => state.product);
	let selectedProduct = useSelector((state) => state.product.selectedProduct);
	let [showModal, setShowModal] = useState(false);
	let [paymentSuccess, setPaymentSuccess] = useState(productSlice.paymentIsSuccessful);
	let [paymentErrorMessage, setPaymentErrorMessage] = useState(productSlice.paymentErrorMessage);

	const orderQuery = useQuery({
		retry: (count, err) => count < 3,
		staleTime: Infinity,
		queryKey: ['orderKey', getItem('orderID')],
		queryFn: () => GetOrderById(getItem('orderID')).then((res) => res.data),
		// onSuccess: (data) => onProductFetchSuccess(data),
	});

	async function startTimer() {
		await new Promise((r) => setTimeout(r, 5000)).then(() => setShowModal(true));
	}
	useEffect(() => {
		startTimer();
	}, []);
	const navigate = useNavigate();

	const placeholders = [
		{
			id: 'product_name',
			display: '@[Product Name](product_name)',
		},
		{
			id: 'product_variation',
			display: '@[Product Variation](product_variation)',
		},
		{
			id: 'product_price',
			display: '@[Product Price](product_price)',
		},
	];

	// const formatSuccessMessage = () => {
	// 	if (orderQuery?.data?.data?.orderItems?.length > 1) {
	// 		let multipleOrderMsg = 'Y’ello! Your order has been placed. Please check Order History for the status of your order #WeDey4U';
	// 		return multipleOrderMsg;
	// 	}

	// 	if (orderQuery?.data?.data?.orderItems?.length > 0) {
	// 		const orderItem = orderQuery?.data?.data?.orderItems[0];
	// 		let modifiedMessage;

	// 		if (selectedProduct?.successMessage?.includes('@[Product Name](product_name)')) {
	// 			modifiedMessage = selectedProduct?.successMessage?.replace('@[Product Name](product_name)', orderItem?.productName);
	// 		}

	// 		if (selectedProduct?.successMessage?.includes('@[Product Variation](product_variation)')) {
	// 			modifiedMessage = selectedProduct?.successMessage?.replace('@[Product Variation](product_variation)', 'variations');
	// 		}

	// 		if (selectedProduct?.successMessage?.includes('@[Product Price](product_price)')) {
	// 			modifiedMessage = selectedProduct?.successMessage?.replace('@[Product Price](product_price)', orderItem?.cost);
	// 		}

	// 		return modifiedMessage;
	// 	}

	// 	return selectedProduct?.successMessage;
	// };

	function getVariationNames(variationArray) {
		const variationNames = variationArray?.map((variation) => variation?.variationName);
		return variationNames?.join(' ');
	}

	const formatSuccessMessage = () => {
		if (orderQuery?.data?.data?.orderItems?.length > 1) {
			let multipleOrderMsg = 'Y’ello! Your order has been placed. Please check Order History for the status of your order #WeDey4U';
			return multipleOrderMsg;
		}
		const orderItem = orderQuery?.data?.data?.orderItems[0];

		function replacePlaceholder(message, placeholder, value) {
			if (message.indexOf(placeholder) !== -1) {
				return message.replace(placeholder, value);
			} else {
				return message;
			}
		}

		const replacedMessage = replacePlaceholder(selectedProduct?.successMessage, '@[Product Name](product_name)', orderItem?.productName);
		const replacedMessage2 = replacePlaceholder(replacedMessage, '@[Product Variation](product_variation)', getVariationNames(orderItem?.variation));
		const finalReplacedMessage = replacePlaceholder(replacedMessage2, '@[Product Price](product_price)', orderItem?.cost);

		return finalReplacedMessage;
	};

	return (
		<>
			<PageTitle title={`${paymentSuccess ? 'Business Hub - Payment Successful' : 'Business Hub - Payment Failed'}`} />

			{orderQuery?.isLoading ? (
				<div className='min-h-[400px] grid place-items-center'>Loading ...</div>
			) : (
				<>
					<div className='bg-white min-h-[400px] p-[30px]'>
						<ReturnButton />
						<p className='font-bold uppercase mt-9 mb-2'>{paymentSuccess && 'order placed'}</p>
						<p className='font-bold text-[34px] mb-3'>{paymentSuccess ? 'Success !' : paymentErrorMessage}</p>
						<p className='mb-11 text-gray-600'>{paymentSuccess ? formatSuccessMessage() : 'Y’ello, your account could not be debited. Kindly confirm you have sufficient funds on your account and pay for your order.'}</p>
						<button className='rounded-full w-full bg-[#ffcc00] p-3 font-bold' onClick={() => navigate(paymentSuccess ? '/orders' : '/')}>
							{paymentSuccess ? 'View Orders' : 'Return to Homescreen'}
						</button>
					</div>
					<div className='w-full bg-[#F2F2F2] py-7 text-center text-gray-600'>
						Your order number is: <span className='font-bold text-gray-600'> {orderQuery?.data?.data?.orderNumber}</span>
					</div>
				</>
			)}

			{/* {showModal && (
				<div className='modal-success'>
					<div className='modal-success-body'>
						<div className='margin-bottom'>
							<div className='modal-success-title'>Manage broadband on myMTN</div>
						</div>
						<div className='center-row align-center'>
							<div className='my-mtn-logo margin-right-10'>
								<div className='no-margin no-padding my'>my</div>
								<MtnLogo className='no-margin no-padding' width={40} />
							</div>

							<span className='text-[12px] text-gray-600'>Conveniently top up data & view your balances on your broadband account with the myMTN app at no extra cost</span>
						</div>
						<button className='download-success-button'>Download myMTN app</button>
						<button onClick={() => setShowModal(false)} className='outline-success-button'>
							Remind me later
						</button>
					</div>
				</div>
			)} */}
			{showModal && (
				<div className='modal-success'>
					<div className='modal-success-body'>
						<div className='margin-bottom'>
							<div className='modal-success-title'>MTN Business Messenger</div>
						</div>
						<div className='center-row align-center'>
							<div className='my-mtn-logo margin-right-10'>
								<div className='no-margin no-padding my'>my</div>
								<MtnLogo className='no-margin no-padding' width={40} />
							</div>

							<span className='text-[12px] text-gray-600'>Send bulk SMS, bulk email and post on all your social media handles at a go with MTN Business Messenger</span>
						</div>
						<button
							onClick={() => {
								setShowModal(false);
								window.open('https://www.mtnmessenger.com/', '_blank');
							}}
							className='download-success-button'
						>
							Go To MTN Business Messenger
						</button>
						<button onClick={() => setShowModal(false)} className='outline-success-button'>
							Remind me later
						</button>
					</div>
				</div>
			)}
		</>
	);
};

export default PaymentStatus;
