import React, {useState} from 'react';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {AddItemToCart, AddItemToCartNew, BuyNow} from '../../api/cart.service';
import {getItem, storeItem} from '../../api/storage.service';
import {GetWorkflowById} from '../../api/workflow.service';
import editorControls from '../../components/reusable/workflowComponents/editorComponents';
import {useAlertMessage} from '../../hooks/useAlertMessage';
import {setAppLoading} from '../../redux/app/appSlice';
import './selectbeneficiary.scss';
import {FormWizard, WizardStage} from '../../components/reusable/form_wizard/_component';
import {formToJSON} from 'axios';
import PageTitle from '../../components/reusable/pageTitle/PageTitle';
import {validator} from '../../utils';
import {setPaymentIsSuccessful} from '../../redux/product/productSlice';

const SelectBeneficiary = () => {
	let formWizard;
	const navigate = useNavigate();
	const productWorkflow = useSelector((state) => state.product.productWorkflow);
	const selectedProduct = useSelector((state) => state.product.selectedProduct);
	const dispatch = useDispatch();
	let {showSuccessMessage, showErrorMessage} = useAlertMessage();
	const queryClient = useQueryClient();
	const [currentTab, setCurrentTab] = useState(0);

	const workflowQuery = useQuery({
		retry: (count, err) => count < 3,
		staleTime: Infinity,
		queryKey: ['getProductWorkflow', selectedProduct?.workflowId],
		queryFn: () => GetWorkflowById(selectedProduct?.workflowId).then((res) => res.data),
		onSuccess: (res) => {
			if (!res.data.success) {
				showErrorMessage(res.data.message, 'error');
			}
		},
	});

	// const getProductWorkflow = (workflowId) => {
	// 	dispatch(setAppLoading(true));
	// 	if (workflowId) {
	// 		GetWorkflowById(workflowId)
	// 			.then((res) => {
	// 				dispatch(setAppLoading(false));
	// 				if (res.data.success) {
	// 					var data = res.data.data;
	// 					dispatch(setProductWorkflow(data));
	// 				} else {
	// 					showErrorMessage(res.data.message, 'error');
	// 				}
	// 			})
	// 			.catch((err) => {
	// 				dispatch(setAppLoading(false));
	// 				showErrorMessage('Sorry! An error occured ', 'error');
	// 				console.log('Error Log:', err);
	// 			});
	// 	}
	// };

	// useEffect(() => {
	// 	if (selectedProduct?.workflowId) {
	// 		getProductWorkflow(selectedProduct?.workflowId);
	// 	}
	// }, [selectedProduct]);

	const getComponent = (controlType, control, index) => {
		const Control = editorControls[controlType + 'Control'];
		if (!Control) return <>{`${controlType} not found !`}</>;

		return (
			<Control
				key={index}
				{...{
					index: index,
					data: control,
				}}
			/>
		);
	};

	let addMutation = useMutation(AddItemToCartNew, {
		onSuccess: (data) => {
			queryClient.invalidateQueries('cartItems');
			showSuccessMessage('Item added successfully', 'success');
			dispatch(setAppLoading(false));
			storeItem('orderID', data);
			setTimeout(() => {
				return (window.location.href = '/choose-payment');
			}, 1000);
		},
		onError: (data) => {
			showErrorMessage('Item could not be added', 'error');
			dispatch(setAppLoading(false));
		},
	});

	const addItemToCart = (payload) => {
		dispatch(setAppLoading(true));
		AddItemToCart({...payload})
			.then((res) => {
				dispatch(setAppLoading(false));
				if (res.data.success) {
					showSuccessMessage(res.data.message, 'success');
					storeItem('orderID', res.data.data);
					setTimeout(() => {
						return (window.location.href = '/choose-payment');
					}, 1000);
				} else {
					showErrorMessage(res.data.message, 'error');
				}
			})
			.catch((err) => {
				dispatch(setAppLoading(false));
				showErrorMessage('Sorry! An error occured ', 'error');
				console.log('Error Log:', err);
			});

		// addMutation.mutate({...payload});
	};

	const buyNow = (payload) => {
		dispatch(setAppLoading(true));
		BuyNow({...payload})
			.then((res) => {
				dispatch(setAppLoading(false));
				if (res.data.success) {
					showSuccessMessage(res.data.message, 'success');
					storeItem('orderID', res.data.data);
					setTimeout(() => {
						return (window.location.href = '/choose-payment');
					}, 1000);
				} else {
					showErrorMessage(res.data.message, 'error');
				}
			})
			.catch((err) => {
				dispatch(setAppLoading(false));
				showErrorMessage('Sorry! An error occured ', 'error');
				console.log('Error Log:', err);
			});
	};

	// const onSubmitHandler = (event) => {
	// 	event.preventDefault();
	// 	dispatch(setAppLoading(true));

	// 	const serializedForm = formToJSON(event.target);
	// 	const payload = {
	// 		userId: getItem('u_msisdn'),
	// 		pricingId: getItem('selectedPricing'),
	// 		quantity: 1,
	// 		discountAmount: 0,
	// 		workflowData: [...serializedForm?.response],
	// 	};

	// 	if (getItem('purchaseMethod') === 'cart') {
	// 		addItemToCart(payload);
	// 	} else {
	// 		buyNow(payload);
	// 	}
	// };

	const onBeforeNext = (stage, data) => {
		return true;
	};

	const onInit = (e) => {
		formWizard = e;
	};

	const onSubmit = (stage, data) => {
		// for (let i = 0; i < data?.response?.length; i++) {
		// 	var checkEmptyValue = validator([{rule: 'empty', message: 'All fields are required'}], data?.response[i]?.value);
		// 	if (checkEmptyValue) return showErrorMessage(checkEmptyValue, 'error');
		// }

		const payload = {
			userId: getItem('u_msisdn'),
			pricingId: getItem('selectedPricing'),
			quantity: 1,
			discountAmount: 0,
			workflowData: [...data?.response],
		};

		if (getItem('purchaseMethod') === 'preOrder') {
			dispatch(setPaymentIsSuccessful(true));
			navigate('/payment-status');
		} else if (getItem('purchaseMethod') === 'cart') {
			addItemToCart(payload);
		} else {
			buyNow(payload);
		}
	};

	const ReturnButton = (props) => {
		const handleNavigation = () => {
			if (formWizard.current() === 0) {
				navigate(-1);
			} else {
				formWizard.prev();
			}
		};

		return (
			<button {...props} onClick={handleNavigation} className='returnButton'>
				BACK
			</button>
		);
	};

	// const handleNext = () => {
	// 	formWizard.next();
	// 	setCurrentTab(currentTab + 1);
	// };
	let controlIndex = -1;

	return (
		<>
			<PageTitle title='Business Hub - Beneficiary' />

			{workflowQuery?.isLoading ? (
				<div className='grid place-items-center h-[500px]'>Loading ...</div>
			) : (
				<div className='sbeneficiary_container'>
					<ReturnButton />

					<FormWizard formId='beneficiary-form' className='w-full' stageHiddenCls='hidden' stageVisibleCls='' beforeNext={onBeforeNext} onInit={onInit} onSubmit={onSubmit} enableIndicator={false} currentStage={currentTab}>
						{workflowQuery?.data?.data?.components &&
							workflowQuery?.data?.data?.components
								?.sort((a, b) => a.orderIndex - b.orderIndex)
								?.map((component, i) => (
									<WizardStage key={i}>
										<h1 className='sbeneficiary__header'>{component?.componentName}</h1>
										<p>{component?.description}</p>

										{component?.controls?.map((control, index) => getComponent(control?.type, control, controlIndex++))}

										<button type='submit' className={`rounded-full w-full bg-[#ffcc00] hover:bg-[#ffcc008c] p-3 mt-8 ${workflowQuery?.data?.data?.components.length === i + 1 ? 'form-wizard-submit' : 'form-wizard-next'}`}>
											{workflowQuery?.data?.data?.components.length === i + 1 ? 'Submit' : 'Continue'}
										</button>
									</WizardStage>
								))}
					</FormWizard>
				</div>
			)}
		</>
	);
};

export default SelectBeneficiary;
