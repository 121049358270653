import React from 'react';
import WifiIcon from '../../assets/icons/wifi.png';
import BroadcastIcon from '../../assets/icons/broadcast (1).png';
import CloudIcon from '../../assets/icons/cloud.png';
import './home.scss';
import ProductList from '../../components/reusable/productList/ProductList';
import Carousel from '../../components/reusable/carousel/Carousel';
import {useAlertMessage} from '../../hooks/useAlertMessage';
import {useDispatch} from 'react-redux';
import {GetHomepageSliders} from '../../api/utilities.service';
import {useQuery} from 'react-query';
import PageTitle from '../../components/reusable/pageTitle/PageTitle';

function Home() {
	const dispatch = useDispatch();
	let {showSuccessMessage, showErrorMessage} = useAlertMessage();

	const carouselQuery = useQuery({
		retry: (count, err) => count < 3,
		staleTime: Infinity,
		queryKey: ['carouselQueryKey'],
		queryFn: () => GetHomepageSliders().then((res) => res?.data?.data),
		enabled: true,
	});

	// const getCarouselImages = () => {
	// 	dispatch(setAppLoading(true));
	// 	GetHomepageSliders()
	// 		.then((res) => {
	// 			dispatch(setAppLoading(false));
	// 			if (res.data.success) {
	// 				setCarouselImages(res.data.data);
	// 			} else {
	// 				showErrorMessage(res.data.message, 'error');
	// 			}
	// 		})
	// 		.catch((err) => {
	// 			dispatch(setAppLoading(false));
	// 			showErrorMessage('Sorry! An error occured ', 'error');
	// 			console.log('Error Log:', err);
	// 		});
	// };

	// useEffect(() => {
	// 	getCarouselImages();
	// }, []);

	return (
		<>
			<PageTitle title='Business Hub - Home Page' />
			<div className='home__navigation'>
				{/* {menuItems.map((item, idx) => (
					<HomeNavItem Icon={item.icon} Text={item.text} key={idx} />
				))} */}
				<div className='homeNavItem mr-7'>
					<img src={WifiIcon} className='homeNavItem__Icon ml-1' />
					<p className='homeNavItem__text'>Bundles</p>
				</div>
				<div className='homeNavItem mr-1'>
					<img src={BroadcastIcon} className='homeNavItem__Icon ml-3' />
					<p className='homeNavItem__text'>Broadband</p>
				</div>
				<div className='homeNavItem'>
					<img src={CloudIcon} className='homeNavItem__Icon ml-7' />
					<p className='homeNavItem__text'>Cloud & Converged</p>
				</div>
			</div>
			<div className=''>{carouselQuery?.isLoading ? <div className='h-[400px] grid place-items-center'>Loading ...</div> : <Carousel carouselImages={carouselQuery?.data} />}</div>
			<div className='home__recommendedProductsContainer'>
				<p className='mb-5'>Recommended Products</p>
				<ProductList />
			</div>
		</>
	);
}

export default Home;
