import TextFieldControl from './TextFieldControl/control';
import MsisdnFieldControl from './MsisdnFieldControl/control';
import CheckboxListControl from './CheckboxListControl/control';
import DateFieldControl from './DateFieldControl/control';
import DropdownListControl from './DropdownListControl/control';
import FileUploadFieldControl from './FileUploadFieldControl/control';
import IDValidationFieldControl from './IDValidationFieldControl/control';
import NumberFieldControl from './NumberFieldControl/control';
import RadioButtonsControl from './RadioButtonsControl/control';
import SlidersFieldControl from './SlidersFieldControl/control';
import BeneficiaryFieldControl from './BeneficiaryFieldControl/control';

const editorControls = {
	TextFieldControl,
	MsisdnFieldControl,
	CheckboxListControl,
	DateFieldControl,
	DropdownListControl,
	FileUploadFieldControl,
	IDValidationFieldControl,
	NumberFieldControl,
	RadioButtonsControl,
	SlidersFieldControl,
	BeneficiaryFieldControl,
};

export default editorControls;
