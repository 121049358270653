import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {GetAllPublishedProducts} from '../../../api/products.service';
import RecommendedProducts from '../recommendedProducts/RecommendedProducts';
import SMEImage from '../../../assets/img/SMEProductImg.jpg';
import {useDispatch, useSelector} from 'react-redux';
import {setAppLoading} from '../../../redux/app/appSlice';
import { useAlertMessage } from '../../../hooks/useAlertMessage';

const ProductList = () => {
	const [productList, setProductList] = useState([]);
	const dispatch = useDispatch();
	let {showSuccessMessage, showErrorMessage} = useAlertMessage();
	const imagePath = useSelector((state) => state.app.config.apiBaseUrl.assetsBaseUrl);

	const getRecommendedProducts = () => {
		dispatch(setAppLoading(true));
		GetAllPublishedProducts()
			.then((res) => {
				dispatch(setAppLoading(false));
				if (res.data.success) {
					let data = res.data.data;
					setProductList([...data] ?? []);
				} else {
					showErrorMessage(res.data.message, 'error');
				}
			})
			.catch((err) => {
				dispatch(setAppLoading(false));
				showErrorMessage('Sorry! An error occured ', 'error')
				console.log("Error Log:", err);
			});
	};

	useEffect(() => {
		getRecommendedProducts();
	}, []);

	return (
		<div className='flex items-center pb-4 p-1 overflow-auto'>
			{productList &&
				productList.map((product, index) => (
					<Link to={'/' + product?.permalink} key={product?.productId}>
						<RecommendedProducts img={imagePath + '/' + product?.productImages[0]} title={product?.productName} description={product?.productShortDescription} price={'233.00'} discount={'238.00'} rating={5} />
					</Link>
				))}
		</div>
	);
};

export default ProductList;
