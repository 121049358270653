import React from "react";
import "./modal.scss";
import { ReactComponent as LoginModalCloseIcon } from "../../../assets/icons/login-modal-close-icon.svg";
import { ReactComponent as InfoIcon } from "../../../assets/icons/info-icon.svg";

function LoginModal() {
  return (
    <div className="modal-bg">
      <div className="modal-body">
        <div className="row-end">
          {/* <div className="close-icon">x</div> */}
          <LoginModalCloseIcon />
        </div>
        <div className="info-icon">
          <InfoIcon />
        </div>
        <h1 className="title">Check out with account</h1>
        <p className="description">
          Create an account or login to checkout with your profile.
        </p>
        <button className="continue-button">Continue Checkout</button>
      </div>
    </div>
  );
}

export default LoginModal;
