import React, {useState, useEffect} from 'react';
import {toast} from 'react-toastify';

export const useAlertMessage = () => {
	const [message, setMessage] = useState(null);

	const notifySuccess = (message) => {
		toast.success(message, {
			position: 'top-center',
			autoClose: 3000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: 'light',
		});
	};
	const notifyError = (message) => {
		toast.error(message, {
			position: 'top-center',
			autoClose: 3000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: 'light',
		});
	};

	useEffect(() => {
		if (message) {
			const {type, text} = message;
			switch (type) {
				case 'success':
					notifySuccess(text);
					break;
				case 'error':
					notifyError(text);
					break;
				default:
			}
			setMessage(null);
		}
	}, [message]);

	function showSuccessMessage(text, type) {
		setMessage({type, text});
	}

	function showErrorMessage(text, type) {
		setMessage({type, text});
	}

	return {showSuccessMessage, showErrorMessage};
}
