import React, {useState, useEffect, useRef} from 'react';
import {AiOutlineMore} from 'react-icons/ai';
import {ThreedotsIcon} from '../icons/ThreedotsIcon';
import {RemoveGroup, RemoveGroupMember} from '../../../api/workflow.service';
import {useAlertMessage} from '../../../hooks/useAlertMessage';
import {setAppLoading} from '../../../redux/app/appSlice';
import {useDispatch, useSelector} from 'react-redux';
import {setSelectedGroup, setSelectedMember} from '../../../redux/product/productSlice';

const CustomDropdownMenu = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => setIsOpen(!isOpen);
	const dropdownRef = useRef(null);
	let {showSuccessMessage, showErrorMessage} = useAlertMessage();
	const selectedGroup = useSelector((state) => state.product.selectedGroup);
	const dispatch = useDispatch();
	useEffect(() => {
		const pageClickEvent = (e) => {
			// If the active element exists and is clicked outside of the dropdown
			if (dropdownRef.current !== null && !dropdownRef.current.contains(e.target)) {
				setIsOpen(!isOpen);
			}
		};

		// If the item is active (ie open) then listen for clicks
		if (isOpen) {
			window.addEventListener('click', pageClickEvent);
		}

		return () => {
			window.removeEventListener('click', pageClickEvent);
		};
	}, [isOpen]);

	const onRemove = () => {
		if (window.confirm(`Are you sure you want to remove this ${props?.type}?`)) {
			setIsOpen(false);
			setAppLoading(true);
			if (props?.type === 'group') {
				RemoveGroup(props?.data.id)
					.then((res) => {
						setAppLoading(false);
						if (res.data.success) {
							showSuccessMessage('Group Removed Successfully', 'success');
							props?.refetch();
						} else {
							showErrorMessage(res.data.message, 'error');
						}
					})
					.catch((err) => {
						setAppLoading(false);
						showErrorMessage('An Error occured !', 'error');
						console.log('Error Log:', err);
					});
			} else {
				if (props?.data?.id) {
					RemoveGroupMember(props?.data.id)
						.then((res) => {
							setAppLoading(false);
							if (res.data.success) {
								showSuccessMessage(res.data.message, 'success');
								dispatch(setSelectedGroup({...selectedGroup, ['groupMembers']: [...selectedGroup?.groupMembers].filter((x) => x.id !== props?.data.id)}));
							} else {
								showErrorMessage(res.data.message, 'error');
							}
						})
						.catch((err) => {
							setAppLoading(false);
							showErrorMessage('An Error occured !', 'error');
							console.log('Error Log:', err);
						});
				} else {
					dispatch(setSelectedGroup({...selectedGroup, ['groupMembers']: [...selectedGroup?.groupMembers].filter((x) => x.temporaryId !== props?.data.temporaryId)}));
				}
			}
		}
	};

	const onEdit = () => {
		setIsOpen(false);
		if (props?.type === 'group') {
			dispatch(setSelectedGroup(props.data));
			props?.setShowModal(true);
		} else {
			dispatch(setSelectedMember(props.data));
			props?.setShowMembers(true);
		}
	};

	return (
		<div ref={dropdownRef} className='relative'>
			<button type='button' className='p-2' onClick={toggle}>
				<ThreedotsIcon className='w-5 h-1' />
			</button>
			{isOpen && (
				<div className='absolute bg-white rounded text-[10px] right-0 shadow z-50 w-[100px]'>
					<ul className='p-1'>
						<li onClick={onEdit} className='hover:bg-gray-200 p-1 pl-3 rounded-sm'>
							Edit
						</li>
						<li onClick={onRemove} className='hover:bg-red-300 p-1 pl-3 rounded-sm'>
							Remove
						</li>
					</ul>
				</div>
			)}
		</div>
	);
};

export default CustomDropdownMenu;
