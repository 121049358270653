import {useRef, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {ReactComponent as PhoneNumberInputIcon} from '../../assets/icons/phone-number-input-icon.svg';
import {ReactComponent as MailIcon} from '../../assets/icons/mail-svgrepo-com.svg';
import {AddShopUser, UserLogin} from '../../api/utilities.service';
import {setAppLoading} from '../../redux/app/appSlice';
import {useDispatch, useSelector} from 'react-redux';
import {getItem, storeItem} from '../../api/storage.service';
import {useAlertMessage} from '../../hooks/useAlertMessage';
import {validator} from '../../utils';
import '../Login/Login.scss';
import {formToJSON} from 'axios';
import CustomRadioGroup from '../../components/reusable/radioGroup/RadioGroup';
import PageTitle from '../../components/reusable/pageTitle/PageTitle';
import {setUserInformation} from '../../redux/user/userSlice';

const Signup = () => {
	// let phoneInput = useRef(null);
	// const dispatch = useDispatch();

	// function verifyPhoneInputOnChange(evt) {
	// 	switch (evt.key) {
	// 		case 'Backspace':
	// 			return;
	// 		case 'Delete':
	// 			return;
	// 		default:
	// 			if (!/^[0-9]+$/.test(evt.target.value)) {
	// 				phoneInput.current.value = phoneInput.current.value.substring(0, phoneInput.current.value.length - 1);
	// 			}
	// 			return;
	// 	}
	// }

	// const handleFormSubmit = () => {
	// 	dispatch(setAppLoading(true));

	// 	var emptyMsisdn = validator([{rule: 'empty', message: 'msisdn is required'}], phoneInput?.current.value);
	// 	var validateMsisdn = validator([{rule: 'msisdn', message: 'msisdn is required'}], phoneInput?.current.value);

	// 	if (emptyMsisdn) {
	// 		dispatch(setAppLoading(false));
	// 		showErrorMessage('Phone number is required', 'error');
	// 		return;
	// 	}

	// 	if (validateMsisdn) {
	// 		dispatch(setAppLoading(false));
	// 		showErrorMessage('Phone number is not valid', 'error');
	// 		return;
	// 	}

	// 	UserLogin({msisdn: phoneInput?.current?.value})
	// 		.then((res) => {
	// 			dispatch(setAppLoading(false));
	// 			if (res.data.success) {
	// 				storeItem('u_msisdn', phoneInput?.current?.value);
	// 				setTimeout(() => {
	// 					navigate('/verify-number');
	// 				}, 100);
	// 			} else {
	// 				showErrorMessage(res.data.message, 'error');
	// 			}
	// 		})
	// 		.catch((err) => {
	// 			dispatch(setAppLoading(false));
	// 			showErrorMessage('Sorry! An error occured ', 'error')
	// 			console.log("Error Log:", err);
	// 		});
	// };

	let navigate = useNavigate();
	const items = ['Individual', 'Business'];
	const [selected, setSelected] = useState(items[0]);
	const dispatch = useDispatch();
	let {showSuccessMessage, showErrorMessage} = useAlertMessage();
	const userState = useSelector((state) => state.user);

	const sumbitHandler = (e) => {
		e.preventDefault();
		let payload = formToJSON(e.target);
		payload.msisdn = getItem('u_msisdn');

		if (selected?.toLowerCase() === 'business') {
			payload.business = true;
		} else {
			payload.business = false;
		}
		dispatch(setAppLoading(true));
		AddShopUser(payload)
			.then((res) => {
				dispatch(setAppLoading(false));
				if (res.data.success) {
					navigate(getItem('AfterVerificationPath'));
					const newUser = {...userState?.userInformation, userExists: true};
					dispatch(setUserInformation(newUser));
				} else {
					showErrorMessage(res.data.message, 'error');
				}
			})
			.catch((err) => {
				dispatch(setAppLoading(false));
				showErrorMessage('Sorry! An error occured ', 'error');
				console.log('Error Log:', err);
			});
	};

	let SignupContent = (
		<div className=''>
			<div className='login-modal-body'>
				<h1 className='title'>Create account</h1>
				<form onSubmit={sumbitHandler} className='space-y-3'>
					<div className='mb phone-number-input-container mb-6 gap-2'>
						<PhoneNumberInputIcon className='phone-number-input-icon' />
						<input type={'tel'} className='phone-number-input w-full text-gray-500' name='msisdn' placeholder='Phone Number' disabled value={getItem('u_msisdn')} />
					</div>
					<div className='mb phone-number-input-container mb-6 gap-2'>
						<PhoneNumberInputIcon className='phone-number-input-icon' />
						<input type={'text'} className='phone-number-input w-full' name={`fullName`} placeholder={`Full Name`} required />
					</div>

					{selected.toLowerCase() === 'business' && (
						<div className='mb phone-number-input-container mb-6 gap-2'>
							<PhoneNumberInputIcon className='phone-number-input-icon' />
							<input type={'text'} className='phone-number-input w-full' name={`businessName`} placeholder={`Business Name`} required />
						</div>
					)}

					<div className='mb phone-number-input-container mb-6 gap-2'>
						<MailIcon className='phone-number-input-icon' />
						<input type={'email'} className='phone-number-input w-full' name='email' placeholder='Email' required />
					</div>
					<div className='pt-5'>
						<CustomRadioGroup position={'horizontal'} items={items} selected={selected} setSelected={setSelected} />
					</div>

					<div className='pt-6'>
						<p className='text-[12px] flex  gap-1'>
							<input type='checkbox' className='accent-black' required />
							By signing up you agree to our
						</p>
						<Link to={'/'} className='font-semibold underline text-[12px] pl-4'>
							Terms & Conditions
						</Link>
					</div>

					<div className='centerItems'>
						<button type='submit' className='login-button'>
							Signup
						</button>
					</div>
				</form>

				{/* <div className='h-[0.2px] bg-[lightgray] my-5'></div>
				<div className='row justify-center mt-6'>
					<span className='text-grey'>Already have an account? </span>
					<Link to={'/login'}>
						<div className='sign-up-text'>Login</div>
					</Link>
				</div> */}
			</div>
		</div>
	);

	return (
		<>
			<PageTitle title='Business Hub - Sign up' />

			<div className='login_container'>
				<div>{SignupContent}</div>
			</div>
		</>
	);
};

export default Signup;
