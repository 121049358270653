import { useState } from "react";

function IDValidationFieldView({ data, index, value }) {
    const [selectedID, setSelectedID] = useState();
    const separator = ";;";

    const allowedIds = () => data?.options?.filter(x => x.key == 'ALLOWED-ID')[0].value.split(separator);

    const changeIdState = (selected) => {
        setSelectedID(selected);
    };

    const selectFieldStyle = {
        width: "259px",
        fontSize: "11px",
    };

    return (
        <>
            <div className="flexColumn requestEntry_field_container">
                <select disabled={true} value={value?.meta}
                    name={`response[${index}][meta]`}
                    required={data?.required}
                    onChange={(e) => changeIdState(e.target.value)}
                    style={selectFieldStyle}
                    className="flexColumn requestEntry_formFields"
                >
                    <option style={{ color: "lightgray" }}>Select ID Type</option>
                    {allowedIds().map((item, item_key) => (
                        <option key={item_key}>{item}</option>
                    ))}
                </select>
                <small className="requestEntry_label">{data?.displayName}</small>

            </div>

            <div className="flexColumn requestEntry_field_container">
                <input
                    className="flexColumn requestEntry_formFields"
                    type="text"
                    readOnly={true}
                    value={value?.value}
                />
                <small className="requestEntry_label">{data?.displayName}</small>
            </div>
        </>
    );
}

export default IDValidationFieldView;
