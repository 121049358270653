import React from 'react';
import {storeItem} from '../../../api/storage.service';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {currency} from '../../../utils';
import {setSelectedProduct} from '../../../redux/product/productSlice';

const ExternalVar = (props) => {
	const navigate = useNavigate();
	const user = useSelector((state) => state.user.user);
	const dispatch = useDispatch();

	return (
		<>
			<div className=''>
				<div className='cardHeader customFlex'>
					<p>{props?.selectedProduct?.pricings?.length > 0 && props?.selectedProduct?.displayPrice && currency + ' ' + props?.selectedProduct?.pricings[0]?.cost}</p>
				</div>
				<div className='cardButtons_container'>
					<a href={props?.selectedProduct?.externalProductLink}>
						<button className='buynow'>Buy now</button>
					</a>

					{/* <button className='addtocart' onClick={() => navigationHandler('/select-beneficiary', 'cart')}>
						Add to cart
					</button> */}
				</div>
				{props?.selectedProduct?.pricings?.length > 0 && (
					<div className='aboutThisItem'>
						<p>About this item</p>
						<table>
							<tbody>
								{props?.selectedProduct?.pricings[0]?.benefits !== null &&
									props?.selectedProduct?.pricings[0]?.benefits?.map((benefit, index) => (
										<tr key={index}>
											<td>{benefit?.description}</td>
											<td className='whitespace-nowrap'>{benefit?.value}</td>
										</tr>
									))}
							</tbody>
						</table>
					</div>
				)}
			</div>
		</>
	);
};

export default ExternalVar;
