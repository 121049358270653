import DynamicBundleCreation from '../../DynamicBundleCreation/DynamicBundleCreation';

function SlidersEntry({data, index}) {
	const separator = ';;';

	const items = data?.options?.find((x) => x.key == 'ITEMS')?.value.split(separator);

	return (
		<>
			<DynamicBundleCreation data={data} items={items} index={index} />
		</>
	);
}

export default SlidersEntry;
