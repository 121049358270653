import React from 'react';

const IndividualComponent = ({setIndividualPhoneNumber}) => {
	return (
		<>
			<div>

                <label className='text-xs uppercase text-gray-400'>recipient phone number</label>
				<input
					type='text'
					className='!mt-1'
					name={`individual`}
                    placeholder='Phone number'
					onChange={e => setIndividualPhoneNumber(e.target.value)}
				/>
			</div>
		</>
	);
};

export default IndividualComponent;
