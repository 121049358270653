import React, {useCallback, useEffect, useState} from 'react';
import OrdersCard from './OrdersCard';
import cart from '../../assets/img/no-orders.svg';
import {order, orders} from './ordersData';
import {useNavigate} from 'react-router-dom';
import {getItem} from '../../api/storage.service';
import {setAppLoading} from '../../redux/app/appSlice';
import {useAlertMessage} from '../../hooks/useAlertMessage';
import {useDispatch} from 'react-redux';
import {GetCustomerOrderHistoryByMsisdn} from '../../api/orders.service';
import {MockOrders} from '../../mock';
import PageTitle from '../../components/reusable/pageTitle/PageTitle';

function Orders() {
	const [orderHistory, setOrderHistory] = useState();
	const [myOrders] = useState(orders);
	const navigate = useNavigate();
	const userMsisdn = getItem('u_msisdn');
	const dispatch = useDispatch();
	let {showSuccessMessage, showErrorMessage} = useAlertMessage();
	const [visibleProducts, setVisibleProducts] = useState(3);

	const loadMore = useCallback(() => {
		setVisibleProducts(visibleProducts + 5);
	}, [visibleProducts]);

	const getOrderHistory = () => {
		dispatch(setAppLoading(true));
		GetCustomerOrderHistoryByMsisdn({userId: userMsisdn})
			.then((res) => {
				dispatch(setAppLoading(false));
				if (res.data.success) {
					setOrderHistory(res?.data?.data);
				} else {
					showErrorMessage(res.data.message, 'error');
				}
			})
			.catch((err) => {
				dispatch(setAppLoading(false));
				showErrorMessage('Sorry! An error occured ', 'error');
				console.log('Error Log:', err);
			});
	};

	useEffect(() => {
		getOrderHistory();
	}, [userMsisdn]);

	return (
		<>
			<PageTitle title='Business Hub - Orders' />

			{orderHistory?.length > 0 ? (
				<div className='order__card__main'>
					<h3 className='text-xl font-bold'>Your Orders</h3>
					{orderHistory?.slice(0, visibleProducts).map((item, i) => (
						<OrdersCard key={i} order={item} />
					))}
					{visibleProducts < orderHistory.length && (
						<div onClick={loadMore} className='load-more-btn-holder'>
							<button className='load-more-btn' id='orders-load-btn'>
								Load more
							</button>
						</div>
					)}
				</div>
			) : (
				<div className='no__orders__main'>
					<h3 className='text-xl font-bold'>Your Order</h3>
					<div className='cart__img__holder'>
						<img className='cart__img' src={cart} />
						<div className='shop-now-btn-holder'>
							<button onClick={() => navigate('/')}>Shop now</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default Orders;
