import React from 'react';
import {storeItem} from '../../../api/storage.service';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {currency} from '../../../utils';
import {setSelectedProduct} from '../../../redux/product/productSlice';

const Simple = (props) => {
	const navigate = useNavigate();
	const userState = useSelector((state) => state.user);
	const dispatch = useDispatch();

	const navigationHandler = (path, purchaseMethod, selectedPricing) => {
		storeItem('AfterVerificationPath', path);
		storeItem('purchaseMethod', purchaseMethod);
		storeItem('selectedPricing', selectedPricing);

		dispatch(setSelectedProduct(props?.selectedProduct));

		if (userState?.user && userState?.userInformation?.userExists) {
			navigate('/select-beneficiary');
		} else {
			props?.onAuthenticateUser(true);
		}
	};
	return (
		<>
			<div className=''>
				<div className='cardHeader customFlex'>
					<p>{props?.selectedProduct?.pricings?.length > 0 && props?.selectedProduct?.displayPrice && currency + ' ' + props?.selectedProduct?.pricings[0]?.cost}</p>
				</div>
				<div className='cardButtons_container'>
					{props?.isPreOrder() ? (
						<button className='addtocart !mt-6 hover:!bg-[#fc0] hover:!border-[#fc0] hover:!border-none' onClick={() => navigationHandler('/select-beneficiary', 'preOrder')}>
							Pre-Order
						</button>
					) : (
						<>
							<button className='buynow' onClick={() => navigationHandler('/select-beneficiary', 'buy', props?.selectedProduct?.pricings[0]?.pricingId)}>
								Buy now
							</button>
						</>
					)}

					{/* <button className='addtocart' onClick={() => navigationHandler('/select-beneficiary', 'cart')}>
						Add to cart
					</button> */}
				</div>
			</div>

			{props?.selectedProduct?.pricings?.length > 0 && (
				<div className='aboutThisItem !mt-7'>
					<p>About this item</p>
					<table>
						<tbody>
							{props?.selectedProduct?.pricings[0]?.benefits !== null &&
								props?.selectedProduct?.pricings[0]?.benefits?.map((benefit, index) => (
									<tr key={index}>
										<td>{benefit?.description}</td>
										<td className='whitespace-nowrap'>{benefit?.value}</td>
									</tr>
								))}
						</tbody>
					</table>
				</div>
			)}
		</>
	);
};

export default Simple;
