import React, {useState} from 'react';
import {useMutation, useQueryClient} from 'react-query';
import {useDispatch} from 'react-redux';
import {SelectItemInCart, UnCheckItemInCart} from '../../../api/cart.service';
import {useAlertMessage} from '../../../hooks/useAlertMessage';
import {setAppLoading} from '../../../redux/app/appSlice';
import './cartcheckbox.scss';

const CartCheckBox = ({item, label, checked}) => {
	let {showSuccessMessage, showErrorMessage} = useAlertMessage();
	const queryClient = useQueryClient();
	const dispatch = useDispatch();

	const checkItemMutation = useMutation(SelectItemInCart, {
		onSuccess: () => {
			queryClient.invalidateQueries('cartItems');
			showSuccessMessage('Item checked', 'success');
			dispatch(setAppLoading(false));
		},
		onError: () => {
			showErrorMessage('Item could not be checked', 'error');
			dispatch(setAppLoading(false));
		},
	});

	const uncheckItemMutation = useMutation(UnCheckItemInCart, {
		onSuccess: () => {
			queryClient.invalidateQueries('cartItems');
			showSuccessMessage('Item unchecked', 'success');
			dispatch(setAppLoading(false));
		},
		onError: () => {
			showErrorMessage('Item could not be unchecked', 'error');
			dispatch(setAppLoading(false));
		},
	});

	const handleItemCheck = () => {
    dispatch(setAppLoading(true));

		if (checked) {
			uncheckItemMutation.mutate(item?.orderItemId);
		} else {
			checkItemMutation.mutate(item?.orderItemId);
		}
	};

	return (
		<div className='checkbox-wrapper'>
			<label>
				<input type='checkbox' checked={checked} onChange={() => handleItemCheck()} className={checked ? 'checked' : ''} />
			</label>
		</div>
	);
};
export default CartCheckBox;
