import React from 'react';
import './recommendedProducts.scss';
import AirpodsImg from '../../../assets/img/airpods.png';
import SaveItemImg from '../../../assets/img/saveItem.png';
import {truncateText} from '../../../utils';

function RecommendedProducts({img, title, price, discount, rating, description}) {
	const noPrice = true;
	return (
		<>
			<div className='recommendedProductsCard bg-white shadow-lg'>
			<div style={{backgroundImage: `url(${img})`}} className="bg-no-repeat bg-cover rounded-xl h-[160px] w-[200px]"></div>

				<p className='recommendedProductsCard_title mt-2'>{truncateText(title, 20)}</p>
				{noPrice ? <small className='mt-0 text-gray-500 text-[11px]'>{truncateText(description, 15)}</small> : <p className='recommendedProductsCard_price'> {'GHc ' + price}</p>}
				<div className='recommendedProductsCard_cardBottom customFlex'>
					<div className='recommendedProductsCard_discount'>
						{noPrice ? (
							''
						) : (
							<p>
								<strike>{discount}</strike>
							</p>
						)}

						<div className='customFlex'>
							{Array(rating)
								.fill()
								.map((_, i) => (
									<small key={i} className='stars'>
										⭐
									</small>
								))}
							<p className='stars_number'></p>
						</div>
					</div>
					<div className='recommendedProductsCard_saveItem'>
						<img alt='save item' src={SaveItemImg} />
					</div>
				</div>
			</div>
		</>
	);
}

export default RecommendedProducts;
