import React from 'react';
import {CheckIcon} from '../icons/CheckIcon';
import {UncheckIcon} from '../icons/UncheckIcon';
import {RadioGroup} from '@headlessui/react';

const CustomRadioGroup = ({items, selected, setSelected, position}) => {
	return (
		<RadioGroup value={selected} onChange={setSelected}>
			<div className={`space-y-3 ${position?.toLowerCase() === "horizontal" ? "flex" : ""} justify-between gap-5`}>
				{[...items].map((_item, idx) => (
					<RadioGroup.Option key={idx} value={_item} className={({active, checked}) => `!mt-0 bg-[#f2f2f2] w-full text-black relative flex cursor-pointer rounded-lg px-5 py-3 focus:outline-none`}>
						{({active, checked}) => (
							<>
								{checked ? (
									<div className='shrink-0 mr-2 text-white'>
										<CheckIcon className='h-4 w-4' />
									</div>
								) : (
									<div className='shrink-0 mr-2 text-white'>
										<UncheckIcon className='h-4 w-4' />
									</div>
								)}
								<div className='flex w-full items-center justify-between'>
									<div className='flex items-center gap-1'>
										<div className='flex items-center'>
											<div className='flex items-center gap-2'>
												{/* <div className='bg-[#fc0] rounded-full p-1'>{icons[_item?.value?.toLowerCase()]}</div> */}
												<RadioGroup.Label as='p' className={`${selected === _item ? "text-black":"text-gray-400"} font-medium text-sm`}>
													{_item}
												</RadioGroup.Label>
											</div>
										</div>
									</div>
								</div>
							</>
						)}
					</RadioGroup.Option>
				))}
			</div>
		</RadioGroup>
	);
};

export default CustomRadioGroup;
