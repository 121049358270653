import React from 'react';
import CartCardCounter from '../cartCardCounter/CartCardCounter';
import CartCheckBox from '../cartCheckBox/CartCheckBox';
import SMEImg from '../../../assets/img/SMEProductImg.jpg';
import './cartcardcheckbox.scss';
import {useAlertMessage} from '../../../hooks/useAlertMessage';
import {RemoveItemFromCart} from '../../../api/cart.service';
import {useMutation, useQueryClient} from 'react-query';
import {useDispatch} from 'react-redux';
import {setAppLoading} from '../../../redux/app/appSlice';

const CartCardCheckbox = (props) => {
	let discount = Math.floor((props?.item?.discountAmount / props?.item?.cost) * 100);
	let {showSuccessMessage, showErrorMessage} = useAlertMessage();
	const dispatch = useDispatch();
	const queryClient = useQueryClient();

	const deleteMutation = useMutation(RemoveItemFromCart, {
		onSuccess: () => {
			queryClient.invalidateQueries('cartItems');
			showSuccessMessage('Item removed successfully', 'success');
			dispatch(setAppLoading(false));
		},
		onError: () => {
			showErrorMessage('Item could not be removed', 'error');
			dispatch(setAppLoading(false));
		},
	});

	const handleRemoveItem = () => {
		dispatch(setAppLoading(true));
		deleteMutation.mutate(props?.item?.orderItemId);
	};

	const handleCost = () => (props?.item?.quantity === 1 ? props?.item?.cost : props?.item?.packageTotal);

	return (
		<div className='cartCard__container'>
			<div className='cartCard__checkBox flex items-center justify-evenly'>
				<CartCheckBox checked={props?.item?.checked} item={props.item} />
				<div className='cartCard__Details shadow-lg'>
					<div className='customFlex justifyEvenly cardCard__Inner'>
						<img src={SMEImg} alt='cart image' className='rounded-2xl' />
						<div className='cartCard__Details_texts'>
							{/* <p className="cartCard__Details__brand">Apple</p> */}
							<p className='cartCard__Details__name'>{props?.item?.productName}</p>
							<p className='cartCard__Details__price'>{`GHc ${handleCost()}`} </p>

							{props?.item?.discountAmount > 0 ? (
								<p className='cartCard__Details__discount'>
									<strike>{discount}</strike>({`${props?.item?.discountAmount}`} off)
								</p>
							) : (
								''
							)}
							{props?.item?.productType?.toLowerCase() === 'virtual' ? <small>Qty: {props?.item?.quantity}</small> : <CartCardCounter />}
						</div>
					</div>
					<div className='cartCard__Buttons customFlex'>
						<div className='cartCard__Buttons_left cursor-pointer' onClick={() => console.log('Added to wishlist')}>
							Add to wishlist
						</div>
						<div className='cartCard__Buttons_right cursor-pointer' onClick={() => handleRemoveItem()}>
							Remove
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CartCardCheckbox;
