import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import './cart.scss';
import CartCardCheckbox from '../../components/reusable/cartCardCheckbox/CartCardCheckbox';
import {ReactComponent as CartImage} from '../../assets/img/cart.svg';
import {ReactComponent as ForwardLeaningQuestionMark} from '../../assets/img/forwardLeaningQuestionMark.svg';
import {ReactComponent as BackwardLeaningQuestionMark} from '../../assets/img/backwardLeaningQuestionMark.svg';
import ProductList from '../../components/reusable/productList/ProductList';
import ReturnButton from '../../components/reusable/returnButton/ReturnButton';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {Checkout, GetCustomerCart, SelectAllItemsInCart, UnCheckAllItemsInCart} from '../../api/cart.service';
import {useNavigate} from 'react-router-dom';
import {useAlertMessage} from '../../hooks/useAlertMessage';
import {setAppLoading} from '../../redux/app/appSlice';
import {getItem, storeItem} from '../../api/storage.service';

function Cart() {
	const user = useSelector((state) => state.user.user);
	const navigate = useNavigate();
	const [allSelected, setAllSelected] = useState(true);

	const {
		isLoading,
		isError,
		error,
		data: cartItems,
	} = useQuery({
		queryKey: ['cartItems'],
		queryFn: () => (user ? GetCustomerCart(getItem('u_msisdn')).then((res) => res.data.data) : null),
	});

	let {showSuccessMessage, showErrorMessage} = useAlertMessage();
	const queryClient = useQueryClient();
	const dispatch = useDispatch();

	const checkAllItemMutation = useMutation(SelectAllItemsInCart, {
		onSuccess: () => {
			queryClient.invalidateQueries('cartItems');
			showSuccessMessage('All Items checked', 'success');
			dispatch(setAppLoading(false));
			setAllSelected(!allSelected);
		},
		onError: () => {
			showErrorMessage('Items could not be checked', 'error');
			dispatch(setAppLoading(false));
		},
	});

	const uncheckAllItemMutation = useMutation(UnCheckAllItemsInCart, {
		onSuccess: () => {
			queryClient.invalidateQueries('cartItems');
			showSuccessMessage('All Items unchecked', 'success');
			dispatch(setAppLoading(false));
			setAllSelected(!allSelected);
		},
		onError: () => {
			showErrorMessage('Items could not be unchecked', 'error');
			dispatch(setAppLoading(false));
		},
	});

	const handleSelectAllItems = () => {
		if (allSelected) {
			uncheckAllItemMutation.mutate(cartItems?.orderId);
		} else {
			checkAllItemMutation.mutate(cartItems?.orderId);
		}
	};

	const handleCheckout = () => {
		dispatch(setAppLoading(true));
		Checkout({userId: getItem('u_msisdn')})
			.then((res) => {
				dispatch(setAppLoading(false));
				if (res.data.success) {
					showSuccessMessage(res.data.message, 'success');
					storeItem('orderID', res.data.data);
					setTimeout(() => {
						return navigate('/choose-payment');
					}, 1000);
				} else {
					showErrorMessage(res.data.message, 'error');
				}
			})
			.catch((err) => {
				dispatch(setAppLoading(false));
				showErrorMessage('Sorry! An error occured ', 'error');
				console.log('Error Log:', err);
			});
	};

	return (
		<>
			{cartItems?.productDetails?.length === 0 ? (
				<EmptyCart />
			) : (
				<>
					<div className='cart__container bg-white'>
						<ReturnButton />

						<p className='mt-4'>Your Cart ({cartItems?.productCount ?? 0})</p>
						<small className='underline cursor-pointer hover:font-bold' onClick={() => handleSelectAllItems()}>
							{allSelected ? 'Unselect all items' : 'Select all items'}
						</small>

						<div style={{marginTop: '60px'}}>
							{cartItems?.productDetails?.map((item, index) => (
								<CartCardCheckbox key={item?.packageId} item={item} />
							))}
						</div>
					</div>
					<div className='cart__bottom'>
						<small>SUBTOTAL OF ITEMS ({cartItems?.totalQuantity})</small>
						<p>GHc {cartItems?.totalCost}.00</p>
						<button onClick={() => handleCheckout()}>Checkout</button>
						<div className='line'></div>
					</div>
					<div className='productslider_container'>
						<p className='suggested_products'>Suggested Products</p>
						<ProductList />
					</div>
				</>
			)}
		</>
	);
}

function EmptyCart() {
	return (
		<>
			<div className='cart__container bg-white'>
				<ReturnButton />
				<div className='justify-center'>
					<div>
						<ForwardLeaningQuestionMark />
						<BackwardLeaningQuestionMark />
					</div>
					<CartImage className='margin-bottom' />
					<h1 className='title margin-bottom'>Sorry, empty cart</h1>
					{/* <Button text={"Start Shopping"} /> */}
				</div>
			</div>
			<div className='grow'></div>
			<div className='separator'></div>
			<div className='productslider_container'>
				<p className='suggested_products'>Suggested Products</p>
				<ProductList />
			</div>
		</>
	);
}

export default Cart;
