import React, { useEffect } from 'react';

function LeavePageAlert() {
  useEffect(() => {
    const handleBeforeUnload = event => {
      event.preventDefault();
      event.returnValue = ''; // This is necessary to trigger the browser's confirmation dialog
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <div>
    </div>
  );
}

export default LeavePageAlert;
