import React from "react";
import { ReactComponent as TwitterIcon } from "../../../assets/icons/twitter.svg";
import { ReactComponent as FacebookIcon } from "../../../assets/icons/facebook.svg";
import { ReactComponent as YoutubeIcon } from "../../../assets/icons/youtube.svg";
import { ReactComponent as InstagramIcon } from "../../../assets/icons/instagram.svg";
import "./footer.scss";

const footerItems = [
  "Terms & Conditions",
  "Privacy Policy",
  "Help Center",
  "Apps",
];

function Footer() {
  return (
    <>
      <div className="footer__container">
        <div className="centerItems">
          <div className="footer__socialIcons customFlex">
            <TwitterIcon className="footer__socialIcon" />
            <YoutubeIcon className="footer__socialIcon" />
            <InstagramIcon className="footer__socialIcon" />
            <FacebookIcon className="footer__socialIcon" />
          </div>
        </div>
        <div className="centerItems">
          <div className="footer__navigation customFlex">
            {footerItems.map((item, key) => (
              <p className="footer__navigationItem" key={key}>
                {item}
              </p>
            ))}
          </div>
        </div>
        <p className="footer__credits">
          © 2019 MTN Group Management Services (Pty) Ltd, All rights reserved
        </p>
      </div>
    </>
  );
}

export default Footer;
