export function GroupIcon(props) {
	return (
		<svg viewBox='0 0 20 14' fill='none' {...props} xmlns='http://www.w3.org/2000/svg'>
			<circle cx='11.0995' cy='3.65422' r='2.41958' stroke='black' stroke-width='0.594282' />
			<path d='M16.02 11.1233C16.02 8.40379 13.8154 6.19922 11.0959 6.19922C8.37645 6.19922 6.17188 8.40379 6.17188 11.1233' stroke='black' stroke-width='0.594282' />
			<circle cx='4.62702' cy='3.49421' r='2.27707' stroke='black' stroke-width='0.55928' />
			<path d='M1.01937 9.34722C1.68177 6.87513 4.22278 5.40807 6.69488 6.07047' stroke='black' stroke-width='0.55928' />
			<circle cx='2.55671' cy='2.55671' r='2.27707' transform='matrix(-1 0 0 1 20.1326 0.9375)' stroke='black' stroke-width='0.55928' />
			<path d='M21.1835 9.34722C20.5211 6.87513 17.9801 5.40807 15.508 6.07047' stroke='black' stroke-width='0.55928' />
		</svg>
	);
}
