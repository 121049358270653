import React, {useState, useEffect} from 'react';
import './choosepayment.scss';
import {BsChevronDown, BsChevronUp} from 'react-icons/bs';
import {useNavigate} from 'react-router-dom';
import airtelIcon from '../../assets/img/airtelpayment.png';
import masterCardIcon from '../../assets/img/masterpayment.png';
import momoIcon from '../../assets/img/momopayment.png';
import visaIcon from '../../assets/img/visapayment.jpg';
import vodaIcon from '../../assets/img/vodapayment.png';
import editIcon from '../../assets/icons/edit.png';
import {ReactComponent as SecureIcon} from '../../assets/icons/security.svg';
import ReturnButton from '../../components/reusable/returnButton/ReturnButton';
import {GetPaymentSessionDetails, UpdatePaymentStatus, TriggerPayViaMOMO, CheckMomoPaymentStatus} from '../../api/payment.service';
import {useDispatch} from 'react-redux';
import {setPaymentErrorMessage, setPaymentIsSuccessful} from '../../redux/product/productSlice';
import {getItem, storeItem, destroyItem} from '../../api/storage.service';
import {useAlertMessage} from '../../hooks/useAlertMessage';
import {setAppLoading} from '../../redux/app/appSlice';
import {toast} from 'react-toastify';
import {isValidPhoneNumber} from '../../utils';
import LeavePageAlert from '../../hooks/LeavePageAlert';
import PageTitle from '../../components/reusable/pageTitle/PageTitle';

function ChoosePayment() {
	const navigate = useNavigate();
	const [navDropdown, setNavDropdown] = useState(false);
	const [fieldContent, setFIeldContent] = useState('MoMo');
	const [isCardPayment, setIsCardPayment] = useState(false);
	const [paymentSessionData, setPaymentSessionData] = useState({});
	const [showFailureModal, setShowFailureModal] = useState(false);
	const [paymentIsSuccess, setPaymentIsSuccess] = useState(false);
	const [orderId, setOrderId] = useState(getItem('orderID'));
	const [msisdn, setMsisdn] = useState(getItem('u_msisdn'));
	const [editPhone, setEditPhone] = useState(false);
	const [msisdnInputEnabled, setMsisdnInputEnabled] = useState(true);

	const dispatch = useDispatch();
	let {showSuccessMessage, showErrorMessage} = useAlertMessage();

	const [fieldImg, setFIeldImg] = useState(momoIcon);

	const handleDropDownMenu = () => {
		setNavDropdown(!navDropdown);
	};

	window.errorCallback = function (error) {
		showErrorMessage('Payment Error', 'error');
		dispatch(setPaymentIsSuccessful(false));
		navigate('/payment-status');
	};

	window.cancelCallback = function () {
		showErrorMessage('Payment cancelled', 'error');
		dispatch(setPaymentIsSuccessful(false));
		navigate('/payment-status');
	};

	window.completeCallback = function (obj) {
		// verify  payment success from backend
		verifyPaymentStatus()
			.then((success) => {
				if (success) {
					//remove embeded payment widget
					var container = document.getElementById('embed-target');
					if (container) {
						container.innerHTML = '';
					}
					dispatch(setPaymentIsSuccessful(true));
					navigate('/payment-status');
				} else {
					showErrorMessage('Payment not succeeded.', 'error');
					dispatch(setPaymentIsSuccessful(false));
					dispatch(setPaymentErrorMessage('Payment Failed'));
					navigate('/payment-status');
				}
			})
			.catch((err) => {
				showErrorMessage('Could not verify payment success.', 'error');
				dispatch(setPaymentIsSuccessful(false));
				navigate('/payment-status');
				console.log('Error Log:', err);
			});
	};
	window.timeoutCallback = function (obj) {
		showErrorMessage('Payment timeout', 'error');
		dispatch(setPaymentIsSuccessful(false));
		navigate('/payment-status');
	};

	// Close the dropdown if the user clicks outside of it
	window.onclick = (e) => {
		if (!e.target.matches('.choosepayment__field')) {
			var customDropdown = document.getElementById('customDropdown');
			if (customDropdown?.classList?.contains('_dropdownON')) {
				setNavDropdown(!navDropdown);
			}
		}
	};

	const dropDownItems = [
		{text: 'MoMo', img: momoIcon},
		{text: 'Vodafone Cash', img: vodaIcon},
		{text: 'AirtelTigo Money', img: airtelIcon},
		{text: 'Mastercard', img: masterCardIcon},
		{text: 'Visa', img: visaIcon},
	];

	const handleSelection = (selectedItem) => {
		let paySection = document.getElementById('pay_section');

		if (selectedItem.text == 'Mastercard' || selectedItem.text == 'Visa') {
			setIsCardPayment(true);
			if (paySection) {
				paySection.style.display = 'none';
			}
		} else {
			setIsCardPayment(false);
			var container = document.getElementById('embed-target');
			if (container) {
				container.innerHTML = '';
			}
			if (paySection) {
				paySection.style.display = 'flex';
			}
		}
		setFIeldContent(selectedItem.text);
		setFIeldImg(selectedItem.img);
	};

	async function verifyPaymentStatus() {
		let sessionData = getItem('sessionData');
		let response = await UpdatePaymentStatus({session: sessionData});
		//  clear order id
		destroyItem('orderID');
		return response.data.success;
	}

	function getSessionDetails() {
		loadSessionDetails({
			id: orderId,
		})
			.then((response) => {
				setPaymentSessionData(response.data.data);
				storeItem('sessionData', response.data.data);
			})
			.catch((err) => {
				showErrorMessage('Sorry! An error occured ', 'error');
				console.log('Error Log:', err);
			});
	}

	const loadSessionDetails = async (payload) => await GetPaymentSessionDetails(payload);

	useEffect(() => {
		dispatch(setAppLoading(false));
		window.sessionStorage.clear();
		//get session details for orderID
		if (isCardPayment) {
			getSessionDetails();
		}
	}, [isCardPayment]);

	useEffect(() => {
		//initialize payment widget using session details
		if (isCardPayment) {
			initialize();
		}
	}, [paymentSessionData]);

	function initialize() {
		try {
			let Checkout = window.Checkout;
			let [sessionId, versionNumber, merchantName, orderId] = window.atob(paymentSessionData).split('-');

			Checkout.configure({
				session: {
					id: sessionId,
					version: versionNumber,
				},
				interaction: {
					merchant: {
						name: merchantName,
					},
					displayControl: {
						billingAddress: 'HIDE',
					},
				},
			});
			Checkout.showEmbeddedPage('#embed-target');
		} catch (e) {
			console.error(e);
		}
	}

	function phoneInputOnChange(e) {
		setMsisdn(e.target.value);
	}

	function editPencilOnClick(e) {
		setMsisdnInputEnabled(!msisdnInputEnabled);
	}

	async function payOnClick(e) {
		if (isCardPayment) return;

		//check if phone number is valid else show toast error.
		if (!isValidPhoneNumber(msisdn)) {
			toast.error('A mobile money account may be either 10 (starting with 0) or 9 digits (starting with any digit other than 0)', {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light',
			});
			return;
		}

		dispatch(setAppLoading(true));
		let payLoad = {
			msisdn: `${msisdn}`,
			orderId: `${orderId}`,
		};

		triggerMomoPayment(payLoad)
			.then((response) => {
				dispatch(setAppLoading(false));
				if (response.data.success) {
					showSuccessMessage(response.data.message, 'success');
					navigate('/confirm-payment');
				} else {
					showErrorMessage(response.data.message, 'error');
				}
			})
			.catch((err) => {
				// alert('Could not trigger momo');
				showErrorMessage('An Error occured !', 'error');
				console.log('Error Log:', err);
				dispatch(setAppLoading(false));
			});
	}

	const triggerMomoPayment = async (payLoad) => await TriggerPayViaMOMO(payLoad);

	return (
		<>
			<PageTitle title='Business Hub - Payment Method' />

			<div className='choosepayment__container'>
				<ReturnButton />
				<LeavePageAlert />
				<h1 className='choosepayment__header textCenter mt-5'>Choose payment method</h1>
				<p className='choosepayment__subHeader'>Please ensure that the number displayed below is where payment will be deducted</p>

				<div className='choosepayment__field customFlex justifySpaceBetween' onClick={handleDropDownMenu}>
					<div className='customFlex'>
						<img src={fieldImg} className='default_img' alt='payment' />
						{fieldContent}
					</div>

					<span>{navDropdown ? <BsChevronUp /> : <BsChevronDown />}</span>
				</div>
				<div className={navDropdown ? '_dropdownON' : '_dropdownOFF'} id='customDropdown'>
					{dropDownItems.map((item, idx) => (
						<div onClick={() => handleSelection(item)} key={idx} id={item.text} className='customFlex choosepayment__dropdown cursor-pointer'>
							<span>
								<img src={item.img} alt='payment' />
							</span>
							{item.text}
						</div>
					))}
				</div>

				{isCardPayment ? (
					<div id='embed-target'></div>
				) : (
					<div className='customFlex justifySpaceBetween choosepayment__field'>
						<input className='w-full' type='number' onChange={phoneInputOnChange} value={msisdn} disabled={msisdnInputEnabled} />
						<span>
							<img src={editIcon} onClick={editPencilOnClick} alt='edit' />
						</span>
					</div>
				)}

				<p className='payment_assurance'>
					Rest assured your transaction is safe. This<br></br> technology safeguards your personal information and guarantees privacy.
				</p>
			</div>

			<div id='pay_section' className='choosepayment__bottom customFlex'>
				<div className='customFlex'>
					<SecureIcon />
					Secure <br></br>Checkout
				</div>
				<button className='hover:bg-[#ffcc00a8]' onClick={payOnClick}>
					Pay
				</button>
			</div>

			{showFailureModal && (
				<div className='modal-success' onClick={() => setShowFailureModal(false)}>
					<div className='modal-success-body'>
						<div className='margin-bottom'>
							<div className='modal-success-title'>Payment Failed</div>
						</div>
						<div className='center-row align-center'>
							<span className='text-[12px] text-gray-600'>Y’ello, your account could not be debited. Kindly confirm you have sufficient funds on your account and pay for your order.</span>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default ChoosePayment;
