import { selectedProduct } from "../mock";
import { getAxios, getConfig } from "../utils";

const apiAxios = () => getAxios(getConfig().apiBaseUrl.productsBaseUrl);

export const GetAllPublishedProducts = async () => await apiAxios().post('/Product/GetAllPublishedProducts');
export const GetAllProductPackageByProductId = async (id) => await apiAxios().post('/Product/GetAllProductPackageByProductId', {id});
export const GetProductPackageDetailsByProductPackageId = async (id) => await apiAxios().post('/Product/GetProductPackageDetailsByProductPackageId', {id});
export const GetProductByPermalink = async (payload) => await apiAxios().post('/Product/GetProductByPermalink', {...payload});

export const GetBundleBenefits = async (payload) => await apiAxios().post('/Product/GetBundleBenefits', {...payload});

// export const GetProductById = async (id) => new Promise((resolve)=>{
//     resolve({data: selectedProduct})
// }) 


