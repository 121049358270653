
function NumberFieldView({ data, index, value }) {
    return (
        <>
            <div className="flexColumn requestEntry_field_container">
                <input
                    type="text"
                    className="requestEntry_formFields"
                    placeholder={data?.options.filter((x) => x.key === "PLACEHOLDER")[0]?.value}
                    value={value?.value}
                    readOnly={true}
                />
                <small className="requestEntry_label">{data?.displayName}</small>

            </div>
        </>
    );
}

export default NumberFieldView;
