import { useState } from 'react';
import { uploadFile } from '../../../../api/upload.service';
import { AiOutlinePlus } from 'react-icons/ai';
import closeIcon from '../../../../assets/img/close.png';
import file from '../../../../assets/img/file.png';
import webCam from '../../../../assets/img/web-camera.png';
import graybg from '../../../../assets/img/gray.jpg';
import { useAlertMessage } from '../../../../hooks/useAlertMessage';
import swal from 'sweetalert';
import { uuid } from '../../../../utils';

const FileUploadFieldEntry = ({data, index}) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [uploadedFiles, setUploadedFiles] = useState([]);
	const seperator = ';;';
	const imageExtensions = ['jpg', 'jpeg', 'webp', 'png'];
	let {showSuccessMessage, showErrorMessage} = useAlertMessage();

	let cameraStream = null;
	let streamEl = null;

	const isExtensionAllowed = (url, allowedExtension) => {
		var re = /(?:\.([^.]+))?$/;
		var ext = re.exec(url)[1]?.toLowerCase();
		return allowedExtension.includes(ext);
	};

	const allowedExtensions = () => data?.options?.filter((x) => x.key == 'EXTENSIONS')[0]?.value?.split(seperator);
	const allowedExtensionsRaw = () => data?.options?.filter((x) => x.key == 'EXTENSIONS')[0]?.value;
	const isLiveCaptureEnabled = () => data?.options?.filter((x) => x.key == 'ENABLE-LIVE-CAPTURE')[0]?.value.toString() == 'true';
	const isUploadDisabled = () => data?.options?.filter((x) => x.key == 'DISABLE-UPLOAD')[0]?.value.toString() == 'true';
	const extensionMessage = () => data?.options?.filter((x) => x.key == 'EXTENSIONS-ERROR-MESSAGE')[0]?.value;
	const maxFileSizeMessage = () => data?.options?.filter((x) => x.key == 'MAX-FILE-SIZE-MB-MESSAGE')[0]?.value;
	const allowedFileSizeMb = () => data?.options?.filter((x) => x.key == 'MAX-FILE-SIZE-MB')[0]?.value ?? '3';

	const minFiles = () => Number(data?.options?.filter((x) => x.key == 'MIN')[0]?.value ?? '1');
	const maxFiles = () => data?.options?.filter((x) => x.key == 'MAX')[0]?.value ?? '1';
	const maxFilesMessage = () => data?.options?.filter((x) => x.key == 'MAX-MESSAGE')[0]?.value;

	const isImage = (url) => {
		var re = /(?:\.([^.]+))?$/;
		var ext = re.exec(url)[1]?.toLowerCase();
		return imageExtensions.includes(ext);
	};

	const uploadSelectedFiles = (formData, uid) => {
		// formData.append('controlId', data?.id);
		console.log("formData", formData)
		// return new Promise((resolve, reject) => {
		// 	uploadFile(formData)
		// 		.then((response) => {
		// 			resolve(response.data);
		// 			showSuccessMessage(response.data.message, 'success');
		// 		})
		// 		.catch((err) => {
		// 			console.log(err);
		// 			removeFile(null, uid);
		// 			showErrorMessage('Upload Failed !', 'error');
		// 		});
		// });
	};

	const handleUploadRequest = (file) => {
		setIsSubmitting(true);
		const inpFile = document.getElementById('_' + data?.id);

		const filesArray = Array.prototype.slice.call(inpFile.files);
		if (filesArray.length > Number(maxFiles())) {
			showSuccessMessage(maxFilesMessage(), 'error');

			return;
		}
		const allowedFiles = filesArray.filter((x) => isExtensionAllowed(x.name, allowedExtensions()));

		const unacceptedFiles = filesArray.filter((x) => !isExtensionAllowed(x.name, allowedExtensions()));

		const isSizeAllowed = (file) => {
			return file.size <= Number(allowedFileSizeMb()) * 1048576;
		};

		if (unacceptedFiles.length > 0) {
			showErrorMessage(extensionMessage(), 'error');
			return;
		}

		var filesSizesNotAllowed = [];
		const uploads = [...allowedFiles]
			.filter((x) => {
				const sizeAllowed = isSizeAllowed(x);
				if (!sizeAllowed) filesSizesNotAllowed = [...filesSizesNotAllowed, x.name];
				return sizeAllowed;
			})
			.map((x) => ({uid: uuid(), name: x.name, preview: isImage(x.name) ? URL.createObjectURL(x) : null, content: x, loading: true}));

		if (filesSizesNotAllowed.length > 0) {
			showErrorMessage(maxFileSizeMessage() + '\r\n\r\nFiles:\n' + filesSizesNotAllowed.join('\n'), 'error');
		}

		const newFiles = [...uploadedFiles, ...uploads];
		setUploadedFiles(newFiles);

		[...uploads].map((file) => {
			if (file.preview) {
				compressImage(file.content, 0.6, 0.5, (newFile) => {
					const formData = new FormData();
					formData.append('UploadedFile', newFile, file.name);
					uploadSelectedFiles(formData, file.uid).then((res) => updateUploadedFilesState(res, file, newFiles));
				});
			} else {
				const formData = new FormData();
				formData.append('UploadedFile', file.content);
				uploadSelectedFiles(formData, file.uid).then((res) => updateUploadedFilesState(res, file, newFiles));
			}
			return file;
		});
	};

	const updateUploadedFilesState = (res, file, newFiles) => {
		if (!res.success) {
			showErrorMessage(res.responseMessage, 'error');

			return;
		}
		const newUploadedFiles = [...newFiles].map((x) => {
			if (x.uid == file.uid) {
				if (x.preview) x.preview = res.data.tempPath;
				x.path = res.data.uploadPath;
				x.loading = false;
			}
			return x;
		});
		setUploadedFiles(newUploadedFiles);
	};

	const startStreaming = (e) => {
		var mediaSupport = 'mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices;

		if (mediaSupport) {
			navigator.mediaDevices
				.getUserMedia({video: true})
				.then(function (mediaStream) {
					cameraStream = mediaStream;
					const span = document.createElement('div');
					const videoEl = document.createElement('video');
					const videoTrack = document.createElement('track');
					videoTrack.kind = 'captions';
					videoEl.id = 'stream';
					videoEl.style.width = '100%';
					videoEl.style.height = '70vh';
					span.innerHTML = videoEl.outerHTML;

					swal({
						content: span,
						className: 'capture-modal',
						buttons: ['Cancel', 'Capture'],
					}).then((capture) => {
						if (capture) {
							const el = document.querySelector('#stream');
							captureSnapshot(el.videoWidth, el.videoHeight);
							stopStreaming();
						} else {
							stopStreaming();
						}
					});
					setTimeout(() => {
						streamEl = document.getElementById('stream');
						streamEl.srcObject = mediaStream;
						streamEl.play();

						setTimeout(() => {
							const el = document.querySelector('#stream');
							el?.setAttribute('media-width', el.videoWidth.toString());
							el?.setAttribute('media-height', el.videoHeight.toString());
						}, 5000);
					}, 1000);
				})
				.catch(function (err) {
					swal('Oops', 'Unable to access camera. Kindly enable camera and allow browser access to the camera.' + err, 'error');
				});
			return;
		}
		swal('Oops', 'Your browser does not support media devices.', 'error');
	};

	const stopStreaming = () => {
		if (null != cameraStream) {
			var track = cameraStream.getTracks()[0];

			track.stop();
			streamEl?.load();
			cameraStream = null;
		}
	};

	const captureSnapshot = (width, height) => {
		if (null != cameraStream) {
			const canvas = document.createElement('canvas');
			canvas.width = width;
			canvas.height = height;

			var ctx = canvas?.getContext('2d');
			ctx.drawImage(streamEl, 0, 0, canvas.width, canvas.height);
			canvas.toBlob((blob) => {
				var file = {uid: uuid(), name: 'live-capture.png', preview: URL.createObjectURL(blob)};

				const newFiles = [...uploadedFiles, ...[file]];
				setUploadedFiles(newFiles);

				var formData = new FormData();
				formData.append('UploadedFile', blob, 'live-capture.png');

				uploadSelectedFiles(formData, file.uid).then((res) => updateUploadedFilesState(res, file, newFiles));
			}, 'image/png');
		}
	};

	const compressImage = (file, resizingFactor, quality, callback) => {
		const _URL = window.URL || window.webkitURL;
		const canvas = document.createElement('canvas');
		const context = canvas.getContext('2d');
		const img = new Image();
		img.onload = function (imgEv) {
			const originalWidth = imgEv.target.width;
			const originalHeight = imgEv.target.height;

			const canvasWidth = originalWidth * resizingFactor;
			const canvasHeight = originalHeight * resizingFactor;

			canvas.width = canvasWidth;
			canvas.height = canvasHeight;

			context.drawImage(img, 0, 0, originalWidth * resizingFactor, originalHeight * resizingFactor);

			// reducing the quality of the image
			canvas.toBlob(
				(blob) => {
					if (blob) {
						callback(blob);
					}
				},
				'image/jpeg',
				quality,
			);
		};
		img.src = _URL.createObjectURL(file);
	};

	const removeFile = (e, uid) => {
		setUploadedFiles([...uploadedFiles].filter((x) => x.uid != uid));
		if (e) e.preventDefault();
	};

	const fieldContainer = {
		fontSize: '12px',
		color: 'red !important',
		width: '289px',
		textAlign: 'left',
	};

	const hideInputField = {
		display: 'none',
	};

	return (
		<>
			<div className='flexColumn requestEntry_field_container py-8' style={fieldContainer}>
				<div className={'error-placeholder error-comp-' + data?.id}></div>
				<input style={hideInputField} multiple={Number(maxFiles()) > 1} type='file' accept={'.' + allowedExtensionsRaw().replace(/;;/g, ', .')} onChange={(e) => handleUploadRequest(e)} id={'_' + data?.id} />
				<div style={{width: '100%'}}>
					{uploadedFiles?.map(({uid, preview, loading}, idx) => (
						<label key={idx} style={{backgroundImage: `url(${preview ?? file})`, backgroundSize: preview ? 'cover' : '80%'}} className='centerItems file-preview'>
							{!loading && <img className='c lose-button' onClick={(e) => removeFile(e, uid)} src={closeIcon} alt='close Icon' />}
							{loading && <div className='loader2'></div>}
						</label>
					))}

					{isLiveCaptureEnabled() && (
						<>
							{uploadedFiles.length < Number(maxFiles()) && (
								<>
									<label onClick={startStreaming} className='centerItems file-preview camera'>
										<img src={webCam} />
									</label>
								</>
							)}
							{!isUploadDisabled() && uploadedFiles.length < Number(maxFiles()) && (
								<>
									<label style={{backgroundImage: `url(${graybg})`}} className='centerItems file-preview add' htmlFor={'_' + data?.id}>
										<AiOutlinePlus />
									</label>
								</>
							)}
						</>
					)}

					{!isLiveCaptureEnabled() && uploadedFiles.length < Number(maxFiles()) && (
						<>
							<label style={{backgroundImage: `url(${graybg})`}} className='centerItems file-preview add' htmlFor={'_' + data?.id}>
								<AiOutlinePlus />
							</label>
						</>
					)}
				</div>

				<small className={data?.required ? 'required requestEntry_label' : 'requestEntry_label'}>{data?.displayName}</small>

				<input
					type='hidden'
					value={uploadedFiles
						?.filter((x) => x.path != null && x.path != '')
						.map((x) => x?.path)
						.join(seperator)}
					name={`response[${index}][value]`}
				/>
				<input type='hidden' name={`response[${index}][meta]`} />
				<input type='hidden' name={`response[${index}][componentId]`} value={data?.componentId} />
				<input type='hidden' name={`response[${index}][id]`} value={data?.id} />
			</div>
		</>
	);
};

export default FileUploadFieldEntry;
