import {useState} from 'react';

function IDValidationFieldEntry({data, index}) {
	const [selectedID, setSelectedID] = useState();
	const separator = ';;';

	const allowedIds = () => data?.options?.filter((x) => x.key == 'ALLOWED-ID')[0].value.split(separator);

	return (
		<>
			<div className='flexColumn requestEntry_field_container'>
				<div className={'error-placeholder error-comp-' + data?.componentId}></div>
				<div className='id-entry'>
					<select name={`response[${index}][meta]`} className='requestEntry_formFields'>
						{allowedIds().map((card, i) => (
							<option value={card} key={i}>
								{card}
							</option>
						))}
					</select>

					<input name={`response[${index}][value]`} placeholder='ID Number' className='requestEntry_formFields' type='text' />
					<input type='hidden' name={`response[${index}][componentId]`} value={data?.componentId} />
					<input type='hidden' name={`response[${index}][id]`} value={data?.id} />
				</div>
				<small className={data?.required ? 'required requestEntry_label' : 'requestEntry_label'}>{data?.displayName}</small>
			</div>
		</>
	);
}

export default IDValidationFieldEntry;
