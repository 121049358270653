import React, { useEffect } from "react";
import { useRef } from "react";
import "./autocomplete.scss";
import { useDispatch } from "react-redux";
import { getItem, storeItem } from "../../../api/storage.service";

const Autocomplete = ({ google }) => {
  const dispatch = useDispatch();
  const inputRef = useRef();

  useEffect(() => {
    const storedLocationDetails = getItem("coordinates");
    const locationDetails = JSON.parse(storedLocationDetails);
    inputRef.current.value =
      locationDetails?.placeName === undefined
        ? ""
        : locationDetails?.placeName;
  }, []);

  var geocoder = new google.maps.Geocoder();

  const handleLocationSearch = () => {
    const val = inputRef.current.value;
    fetchResults(val);
    pullUpSearchBox();
  };

  const pullUpSearchBox = () => {
    document.getElementById("map").style.display = "none";
    document.getElementById("results").style.height = "288px";
  }

  function fetchResults(input) {
    const displaySuggestions = function (predictions, status) {
      if (status != google.maps.places.PlacesServiceStatus.OK || !predictions) {
        //console.log(status);
        return;
      }

      var container = document.getElementById("results");
      container.innerHTML = "";
      //console.log("predictions", predictions);

      predictions.forEach((prediction) => {
        var div = document.createElement("div");
        div.appendChild(document.createTextNode(prediction.description));
        div.onclick = function () {
          geocoder.geocode(
            {
              placeId: prediction.place_id,
            },
            function (results, status) {
              if (status === "OK") {
                if (results[0]) {
                  var lat = results[0].geometry.location.lat();
                  var lng = results[0].geometry.location.lng();
                  // dispatch(setLocation({
                  //   latitude : lat,
                  //   longitude: lng,
                  // }))
                  window.location.href = "/map?lat=" + lat + "&lng=" + lng;
                  const coordinates = {
                    latitude: lat,
                    longitude: lng,
                    placeName: prediction.description,
                  };
                  storeItem(JSON.stringify(coordinates), "coordinates");
                }
              }
            }
          );
        };
        container.appendChild(div);
      });
    };

    const service = new google.maps.places.AutocompleteService();

    service.getPlacePredictions(
      { input, componentRestrictions: { country: "GH" } },
      displaySuggestions
    );
  }

  return (
    <div className="location_search_box">
      <input
        className="location_search_input"
        placeholder="Search Location"
        ref={inputRef}
        onChange={() => handleLocationSearch()}
      />
    </div>
  );
};

export default Autocomplete;
