import React, {useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {getItem} from '../../api/storage.service';
import {CheckShopUser, ResendOtp, VerifyOtp} from '../../api/utilities.service';
import ReturnButton from '../../components/reusable/returnButton/ReturnButton';
import {useAlertMessage} from '../../hooks/useAlertMessage';
import {setAppLoading} from '../../redux/app/appSlice';
import {setLogin, setUserInformation} from '../../redux/user/userSlice';
import {cacheUserData} from '../../utils';
import './verification.scss';
import Countdown from 'react-countdown';
import PageTitle from '../../components/reusable/pageTitle/PageTitle';

const VerifyNumber = () => {
	let verificationInput = useRef(null);
	let navigate = useNavigate();
	const dispatch = useDispatch();
	let {showSuccessMessage, showErrorMessage} = useAlertMessage();
	let [showOTPTimer, setShowOTPTimer] = useState(true);

	function verifyPinInputOnChange(evt) {
		switch (evt.key) {
			case 'Backspace':
				return;
			case 'Delete':
				return;
			default:
				if (!/^[0-9]+$/.test(evt.target.value)) {
					verificationInput.current.value = verificationInput.current.value.substring(0, verificationInput.current.value.length - 1);
				}
				return;
		}
	}

	const checkIfUserExist = (userExists) => {
		if (userExists) {
			navigate(getItem('AfterVerificationPath') ?? '/');
		} else {
			navigate('/signup');
		}
	};

	const handleFormSubmit = (e) => {
		e.preventDefault();
		//validations
		dispatch(setAppLoading(true));
		VerifyOtp({otp: verificationInput?.current?.value, msisdn: getItem('u_msisdn')})
			.then((res) => {
				dispatch(setAppLoading(false));
				if (res?.data?.success) {
					cacheUserData('username', res?.data?.data?.token, res?.data?.data?.expiry);
					dispatch(setLogin(true));
					dispatch(setUserInformation(res?.data?.data));
					checkIfUserExist(res?.data?.data.userExists);
				} else {
					showErrorMessage(res?.data?.message, 'error');
				}
			})
			.catch((err) => {
				dispatch(setAppLoading(false));
				showErrorMessage('Sorry! An error occured ', 'error');
				console.log('Error Log:', err);
			});
	};

	const renderer = ({minutes, seconds, completed}) => {
		if (completed) {
			return setShowOTPTimer(false);
		} else {
			return (
				<div className='text-sm text-center mt-8'>
					Resend OTP in <span className='min font-bold'>{minutes}</span>:<span className='sec font-bold'>{seconds}</span> secs
				</div>
			);
		}
	};

	const handleOTPResend = () => {
		setShowOTPTimer(true);
		// API Integration
		ResendOtp({msisdn: getItem('u_msisdn')})
			.then((res) => {
				dispatch(setAppLoading(false));
				if (res.data.success) {
					showSuccessMessage('OTP has been re-sent', 'success');
				} else {
					showErrorMessage(res.data.message, 'error');
				}
			})
			.catch((err) => {
				dispatch(setAppLoading(false));
				showErrorMessage('Sorry! An error occured ', 'error');
				console.log('Error Log:', err);
			});
	};

	let verifyNumberContent = (
		<div className='verification-content'>
			<div className='mb-6'>
				<ReturnButton />
			</div>
			<h1 className='font-bold text-[21px] mb-3'>Verify your contact number</h1>
			<h2 className='font-bold text-md mb-3'>Enter one time pin</h2>
			<p className='text-sm text-gray-400 mb-3'>You will receive a one time pin SMS on your phone. Please, enter the code below.</p>
			<form onSubmit={handleFormSubmit}>
				<input required={true} ref={verificationInput} onKeyUp={verifyPinInputOnChange} className='w-full text-sm rounded-xl bg-[#f2f2f2] my-2 outline-none p-3 pl-4' placeholder='Enter pin' type='password' />

				<button type='submit' className='w-full p-[10px] mt-7 rounded-3xl bg-[#ffcc00] text-[14px]'>
					Confirm
				</button>
			</form>

			<div className='resend-link-container'>
				{showOTPTimer ? (
					<Countdown date={Date.now() + 59000} renderer={renderer} />
				) : (
					<p onClick={() => handleOTPResend()} className='mt-8 text-sm underline hover:font-bold cursor-pointer'>
						Resend the code
					</p>
				)}
			</div>
		</div>
	);

	return (
		<>
			<PageTitle title='Business Hub - Verify OTP' />
			<div className='login_container'>{verifyNumberContent}</div>
		</>
	);
};

export default VerifyNumber;
