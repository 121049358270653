import {getAxios, getConfig} from '../utils';

const apiAxios = () => getAxios(getConfig().apiBaseUrl.workflowBaseUrl);

export const GetWorkflowById = async (id) => await apiAxios().post('/Workflow/GetWorkflowWithComponentData', {id});
export const AddCustomerInput = async (payload) => await apiAxios().post('/Workflow/AddCustomerInput', {...payload});

export const GetGroupsByMsisdn = async (msisdn) => await apiAxios().post('/BeneficiaryGroups/GetGroupsByMsisdn', {msisdn});
export const AddGroup = async (payload) => await apiAxios().post('/BeneficiaryGroups/AddGroup', {...payload});
export const RemoveGroup = async (id) => await apiAxios().post('/BeneficiaryGroups/RemoveGroup', {id});
export const UpdateGroup = async (payload) => await apiAxios().post('/BeneficiaryGroups/UpdateGroup', {...payload});

export const RemoveGroupMember = async (id) => await apiAxios().post('/BeneficiaryGroups/RemoveGroupMember', {id});
export const UpdateGroupMember = async (payload) => await apiAxios().post('/BeneficiaryGroups/UpdateGroupMember', {...payload});
export const AddGroupMember = async (payload) => await apiAxios().post('/BeneficiaryGroups/AddGroupMember', {...payload});
