import {useRef} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {LoginModal} from './modal';
import './Login.scss';
import {ReactComponent as PhoneNumberInputIcon} from '../../assets/icons/phone-number-input-icon.svg';
import {ReactComponent as FacebookLoginIcon} from '../../assets/icons/facebook-login-icon.svg';
import {ReactComponent as TwitterLoginIcon} from '../../assets/icons/twitter-login-icon.svg';
import {ReactComponent as GoogleLoginIcon} from '../../assets/icons/google-login-icon.svg';
import {UserLogin} from '../../api/utilities.service';
import {setAppLoading} from '../../redux/app/appSlice';
import {useDispatch} from 'react-redux';
import {storeItem} from '../../api/storage.service';
import {setLogin} from '../../redux/user/userSlice';
import {useAlertMessage} from '../../hooks/useAlertMessage';
import {validator} from '../../utils';
import PageTitle from '../../components/reusable/pageTitle/PageTitle';

const Login = () => {
	let phoneInput = useRef(null);
	let navigate = useNavigate();
	const dispatch = useDispatch();
	let {showSuccessMessage, showErrorMessage} = useAlertMessage();

	function verifyPhoneInputOnChange(evt) {
		switch (evt.key) {
			case 'Backspace':
				return;
			case 'Delete':
				return;
			default:
				if (!/^[0-9]+$/.test(evt.target.value)) {
					phoneInput.current.value = phoneInput.current.value.substring(0, phoneInput.current.value.length - 1);
				}
				return;
		}
	}

	const handleFormSubmit = () => {
		dispatch(setAppLoading(true));

		var emptyMsisdn = validator([{rule: 'empty', message: 'msisdn is required'}], phoneInput?.current.value);
		var validateMsisdn = validator([{rule: 'msisdn', message: 'msisdn is required'}], phoneInput?.current.value);

		if (emptyMsisdn) {
			dispatch(setAppLoading(false));
			showErrorMessage('Phone number is required', 'error');
			return;
		}

		if (validateMsisdn) {
			dispatch(setAppLoading(false));
			showErrorMessage('Phone number is not valid', 'error');
			return;
		}

		UserLogin({msisdn: phoneInput?.current?.value})
			.then((res) => {
				dispatch(setAppLoading(false));
				if (res.data.success) {
					storeItem('u_msisdn', phoneInput?.current?.value);
					setTimeout(() => {
						navigate('/verify-number');
					}, 100);
				} else {
					showErrorMessage(res.data.message, 'error');
				}
			})
			.catch((err) => {
				dispatch(setAppLoading(false));
				showErrorMessage('Sorry! An error occured ', 'error');
				console.log('Error Log:', err);
			});
	};

	let loginContent = (
		<div className='content'>
			<div className='login-modal-body'>
				<h1 className='title'>Login to Continue</h1>
				<div className='mb phone-number-input-container mb-6 gap-2'>
					<PhoneNumberInputIcon className='phone-number-input-icon' />
					<input ref={phoneInput} type={'tel'} className='phone-number-input w-full' placeholder='Phone number' onKeyUp={verifyPhoneInputOnChange} pattern='[0-9]{3}-[0-9]{3}-[0-9]{4}' required />
				</div>
				<div className='mt-10 row justifySpaceBetween '>
					{/* <div className='row align-center'>
						<input className='remember-me-checkbox' type={'checkbox'} />
						<span className='remember-me-text'>Remember me</span>
					</div> */}
					{/* <div className='row align-center'>
						<a className='forgot-password-link'>Forgot password</a>
					</div> */}
				</div>
				<div className='centerItems'>
					<div onClick={() => handleFormSubmit()} className='login-button'>
						Login
					</div>
				</div>
				<div className=' justify-center login-socials-link'>or login with social account</div>
				<div className='social-links'>
					<div className='social-link-container'>
						<FacebookLoginIcon className='social-icon' />
					</div>
					<div className='social-link-container'>
						<TwitterLoginIcon className='social-icon' />
					</div>
					<div className='social-link-container'>
						<GoogleLoginIcon className='social-icon' />
					</div>
				</div>
				{/* <div className='h-[0.2px] bg-[lightgray] my-3'></div>
				<div className=' row justify-center mt-6'>
					<span className='text-grey'>Don't have an acccount? </span>
					<Link to={'/signup'}>
						<div className='sign-up-text'>Sign up</div>
					</Link>
				</div> */}
			</div>
		</div>
	);

	return (
		<>
			<PageTitle title='Business Hub - Login' />

			<div className='login_container'>
				<div>{loginContent}</div>
				{/* <div>{<LoginModal />}</div> */}
			</div>
		</>
	);
};

export default Login;
