import React from 'react';
import './orders.scss';
import tnet from '../../assets/img/svgs/tnet-img.svg';
import {useNavigate} from 'react-router-dom';
import moment from 'moment/moment';
import SMEImg from '../../assets/img/SMEProductImg.jpg';
import { storeItem } from '../../api/storage.service';

function OrdersCard({order}) {
	const navigate = useNavigate();
	const productCount = () => Number(order?.productCount);

	return (
		<>
			<div className='product-info'>
				<div className='card-top'>
					<div className='card-top-leftside'>
						<span className='font-bold'>{`ORDER #${order?.orderNumber}`}</span>
						<br />
						<span className='text-xs uppercase'>{moment(order?.cartDate).format('Do MMMM YYYY')}</span>
					</div>
					<div className='card-top-rightside'>
						<button className='order-v'>{order?.orderStatus}</button>
					</div>
				</div>
				<div className='product-details'>
					<div className='customer-momo-card-top-left'>
						<img src={SMEImg} className='rounded-2xl' />
					</div>
					<div className='customer-momo-card-top-right'>
						<div className='huawei-text'>{order?.productDetails[0]?.productName}</div>
						<div>
							<span className='text-sm'>{order?.productDetails[0]?.packageName}</span>
						</div>
						<div className='price-text'>{Number(order?.productDetails[0]?.packageTotal)?.toFixed(2)}</div>
						{productCount() >= 2 ? (
							<div className='text-xs mt-2'>
								and <b className='font-bold'>{productCount() - 1}</b> other product(s)
							</div>
						) : (
							''
						)}
					</div>
					<div className='footer-info flex justify-center'>
						{order?.orderStatus.toLowerCase() === 'not paid' ? (
							<button
								onClick={() => {
									storeItem('orderID', order?.orderId);
									navigate('/choose-payment');
								}}
								className='view-order-btn mr-2'
							>
								Pay Now
							</button>
						) : (
							<></>
						)}

						<button
							onClick={() => {
								navigate(`/order-details/${order?.orderId}`);
							}}
							className='view-order-btn'
						>
							View/Track Order
						</button>
						<button className='x1-btn'>{`x${order?.productCount}`}</button>
					</div>
				</div>
			</div>
		</>
	);
}

export default OrdersCard;
