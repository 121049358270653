import { createSlice } from "@reduxjs/toolkit";

export const locationSlice = createSlice({
  name: "location",
  initialState: {
    location: {},
  },
  reducers: {
    setLocation: (state, action) => {
      state.location = action.payload;
    },
    setLocationNull: (state) => {
      state.location = {};
    },
  },
});

export const { setLocation, setLocationNull } = locationSlice.actions;

export const selectLocation = (state) => state.location.location;

export default locationSlice.reducer;