export function UncheckIcon(props) {
	return (
		<svg viewBox='0 0 24 24' fill='none' {...props}>
			<title />
			<g id='Complete'>
				<g id='Circle'>
					<circle cx='12' cy='12' data-name='Circle' fill='none' id='Circle-2' r='10' stroke='#000000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' />
				</g>
			</g>
		</svg>
	);
}
