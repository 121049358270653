
function TextFieldView({ data, index, value }) {

    return (
        <>
            <div className="flexColumn requestEntry_field_container">
                {data?.options.filter((x) => x.key === "MULTI-LINE")[0]?.value ===
                    "true" ? (
                    <textarea
                        className="requestEntry_formFields"
                        readOnly={true}
                        value={value?.value}
                    ></textarea>
                ) : (
                    <input
                        type="text"
                        className="requestEntry_formFields"
                        value={value?.value}
                        readOnly={true}
                    />
                )}

                <small className="requestEntry_label">{data?.displayName}</small>

            </div>
        </>
    );
}

export default TextFieldView;
